import { ExcelExport, ExcelExportColumnProps } from '@progress/kendo-react-excel-export'
import { Splitter, SplitterOnChangeEvent, SplitterPaneProps } from '@progress/kendo-react-layout'
import usePrintPreviewGlobalState from 'hooks/usePrintPreviewGlobalState'
import useRecalculateItemsStatus from 'hooks/useRecalculateItemsStatus'
import useUser from 'hooks/useUser'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { handleApiError, handleRecalculationError } from 'services/utilities/toastrUtils'
import { RecalculationState } from 'store/recalculationStore'
import { RootState } from 'store/store'
import ReportingMetadata from 'types/ReportingMetadata'
import { DrilldownSelection, getReportUsedColorsString, Report } from 'types/Reports'
import IconButton from 'views/Common/Buttons/IconButton'
import IconButtonColumns from 'views/Common/Buttons/IconButtonColumns'
import ReportRefreshButton from 'views/Common/Buttons/ReportRefreshButton'
import SeperatorVertical from 'views/Common/Buttons/SeparatorVertical'
import ConfirmSaveLibraryItem from 'views/Common/GenericDialogs/ConfirmSaveLibraryItem'
import SimpleDialog from 'views/Common/GenericDialogs/SimpleDialog'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import KendoGridCustom from 'views/Common/Kendo/KendoGridCustom'
import GridPageLayout from 'views/Common/Layout/PageLayout'
import RowBreadcrumbs from 'views/Common/Layout/RowBreadcrumbs'
import ReportChart from '../Components/ReportChart'
import { getGridFormattedReportData } from '../Components/reportConversion'
import useReportDrilldownData from '../Hooks/useReportDrilldownData'

/**
 * Limit the data.
 */
const MaxReportCategories = 250

/**
 * Constrain the splitter
 */
const ChartMinHeight = 50
const GridMinHeight = 50
const PaddingBetweenChartAndGrid = 30

type DialogMode = 'ConfirmSaveToLibrary' | 'None'

const ReportView = (props: { isPrintPreview?: boolean }) => {
    const api = globals.getApi()
    const navigate = useNavigate()
    const processingApi = api.getProcessingApi()
    const reportingApi = api.getReportingApi()
    const dispatch = useDispatch()
    const [contentHeight, setContentHeight] = useState(0)
    const [dialogMode, setDialogMode] = useState<DialogMode>('None')
    const synchronousOnly = useSelector<RootState, boolean | undefined>((x) => x.app.user?.refreshReportsSynchronously)
    const [chartPaneHeightPercent, setChartPaneHeightPercent] = useState<number>(0)
    const [splitterPanes, setSplitterPanes] = useState<Array<SplitterPaneProps>>([
        { scrollable: false, min: `${ChartMinHeight}px` },
        { scrollable: false },
    ])
    const user = useUser()

    const exportRef = useRef<ExcelExport | null>(null)
    const exportRefRegularGrid = useRef<ExcelExport | null>(null)
    const [showGridColumnPicker, setShowGridColumnPicker] = useState(false)
    const [drilldownSelection, setDrilldownSelection] = useState<DrilldownSelection | undefined>(undefined)
    const [drilldownData, setDrilldownData] = useState<any[] | null>(null)
    const [drilldownColumns, setDrilldownColumns] = useState<KendoGridColumn[]>([])
    const [drilldownError, setDrilldownError] = useState<string | null>(null)
    const [report, setReport] = useState<Report | undefined>()
    const [synchronousRefreshTime, setSynchronousRefreshTime] = useState<Date | null>(null)
    const [isSynchronousRefreshing, setIsSynchronousRefreshing] = useState(false)
    const [reportMetadata, setReportMetadata] = useState<ReportingMetadata | null>(null)
    const recalculationState = useSelector<RootState, RecalculationState>((x) => x.reportsRecalculation)
    const isRefreshing =
        (report !== undefined && recalculationState.itemsBeingRecalculated.includes(report.id)) ||
        isSynchronousRefreshing

    const [recalculationCompletedTime, reportsRecalculationState] = useRecalculateItemsStatus('Report', 2000)

    const params = useParams() as any

    // initial data load
    useEffect(() => {
        const loadData = async () => {
            try {
                const reportId = parseInt(params.id)
                const loadedReport = await reportingApi.getReport(reportId)
                const usedColorsString = getReportUsedColorsString(loadedReport)
                const metadata = await reportingApi.getReportsConfigMetadata(usedColorsString)
                setReport(loadedReport)
                setReportMetadata(metadata)
                setIsSynchronousRefreshing(false)

                if (loadedReport.hasConfigOptions) {
                    const isOrderedByDataGroupingItem =
                        loadedReport.configOptions?.orderItem === loadedReport.configOptions?.xAxisItem
                    const hasDataReachedLimit =
                        loadedReport.xAxisValues?.length === loadedReport.configOptions?.limitResults
                    const message =
                        'Useful data may be missing from this report due to the Order and Limit settings. Ordering by one of the data series values may provide more useful results.'
                    if (hasDataReachedLimit && isOrderedByDataGroupingItem) {
                        toast.warn(message, {
                            closeButton: true,
                            autoClose: 8000,
                            pauseOnHover: true,
                            position: 'top-center',
                        })
                    }
                }

                if (recalculationCompletedTime) {
                    toast.success('Report data has been refreshed')
                }
                document.title = `SAFTE-FAST - ${loadedReport.name}`
            } catch (e: any) {
                handleApiError(e)
            }
        }
        loadData()
    }, [params.id, reportingApi, recalculationCompletedTime, synchronousRefreshTime])

    usePrintPreviewGlobalState(props.isPrintPreview)

    // fetch drilldown data on change of drildownSelection
    useReportDrilldownData(
        report,
        reportMetadata,
        drilldownSelection,
        setDrilldownData,
        setDrilldownColumns,
        setDrilldownError,
    )

    const saveToLibrary = async (mode: 'ConfirmedName' | 'CheckForDuplicateName', name: string) => {
        // check if report is already in the library with the same name
        const libraryReports = await reportingApi.getReportsLibrary()
        const duplicatedReport = libraryReports.find((x) => x.name === report!.name && x.createdById === user?.id)

        if (duplicatedReport && mode === 'CheckForDuplicateName') {
            setDialogMode('ConfirmSaveToLibrary')
            return
        }

        // When Report is overwriting an existing Report
        const reportId = duplicatedReport !== undefined && duplicatedReport.name === name ? duplicatedReport!.id : 0

        const reportForLibrary: Report = {
            ...report!,
            id: reportId,
            name,
            isLibraryReport: true,
            includeAllMyScenarios: false,
        }

        if (reportForLibrary.configOptions) {
            // remove all the child series that are there for each scenario.
            reportForLibrary.configOptions.series = reportForLibrary.configOptions.series.filter(
                (s) => s.isChildSeries !== true,
            )

            // in case this report was only being used in a scenario that wasn't the first one in the project,
            // we need to change the id in the series here to avoid issues.  Issue was that it was creating new
            // project reports including this one with a scenario id (like 2, or 3) that doesn't exist in the new project.
            // Other code isn't accounting for that (like when reports are created out of the library in c#), so we account
            // for it here.
            reportForLibrary.configOptions.series.forEach((s) => {
                s.scenarioId = 1
            })
        }

        // Overwrite a Report
        if (duplicatedReport && duplicatedReport.name === name) {
            const existingReportIndex = libraryReports.findIndex((o) => o.name === reportForLibrary.name)
            libraryReports[existingReportIndex] = reportForLibrary
            await reportingApi.saveReportsLibrary(libraryReports)
            // Add a Report
        } else {
            const updatedLibraryReports = [...libraryReports, reportForLibrary]
            await reportingApi.saveReportsLibrary(updatedLibraryReports)
        }

        toast.success('Saved to Library')
    }

    handleRecalculationError(reportsRecalculationState.recalculationErrorMessage, dispatch, 'report')

    let toolbarButtons = <></>
    if (report) {
        if (props.isPrintPreview) {
            toolbarButtons = (
                <>
                    <IconButton tooltip="Go Back" toolbarLeftMargin onClick={() => navigate(-1)} icon="bi-arrow-left" />
                    <IconButton tooltip="Print" toolbarLeftMargin onClick={() => window.print()} icon="bi-printer" />
                </>
            )
        } else {
            toolbarButtons = (
                <>
                    {drilldownSelection && (
                        <>
                            <IconButton
                                tooltip="Clear Data Category Selection"
                                toolbarLeftMargin
                                onClick={() => {
                                    setDrilldownSelection(undefined)
                                    setDrilldownData(null)
                                }}
                                icon="bi-x-circle"
                            />
                            <SeperatorVertical />
                        </>
                    )}
                    <>
                        <ReportRefreshButton
                            isRefreshing={isRefreshing}
                            disabled={isRefreshing || !report.requiresRecalculation}
                            requiresRefresh={report.requiresRecalculation!}
                            onClick={() => {
                                if (synchronousOnly) {
                                    setIsSynchronousRefreshing(true)
                                    setSynchronousRefreshTime(new Date())
                                } else {
                                    processingApi.beginItemRefresh([report.id], 'Report', dispatch)
                                }
                            }}
                        >
                            Refresh
                        </ReportRefreshButton>
                        <SeperatorVertical />
                    </>
                    <IconButton
                        tooltip="Edit Report Configuration"
                        toolbarLeftMargin
                        disabled={isRefreshing}
                        onClick={() => navigate(`/reports/${report.id}/configuration`)}
                        icon="bi-pencil"
                    />
                    <IconButton
                        tooltip="Save this Report to your Library"
                        toolbarLeftMargin
                        disabled={isRefreshing}
                        onClick={() => saveToLibrary('CheckForDuplicateName', report.name)}
                        icon="bi-save2"
                    />
                    <IconButton
                        tooltip="Print Preview"
                        toolbarLeftMargin
                        disabled={isRefreshing}
                        onClick={() => navigate(`/reports-print/${report.id}`)}
                        icon="bi-printer"
                    />
                </>
            )
        }
    }

    const breadCrumbs = (
        <RowBreadcrumbs entries={[{ label: 'Reports Dashboard', url: '/reports' }, { label: 'Report View' }]} />
    )

    const exportFormatColumns = drilldownColumns
        .filter((col) => !col.hide)
        .map<ExcelExportColumnProps>((col) => ({
            field: col.field,
            title: col.title,
        }))

    // don't show the report if there is too much data
    let reportTooMuchDataError = null
    if ((report?.xAxisValues?.length ?? 0) > MaxReportCategories) {
        reportTooMuchDataError = (
            <Alert variant="danger">
                {`This report produced ${
                    report!.xAxisValues!.length
                } categories of data. The maximum allowable number of data categories that can be
                shown in ${MaxReportCategories}. You may modify or remove this report.`}
            </Alert>
        )
    }

    let reportContent = <>Loading...</>

    // if this is false, the data could be processing  on the server
    const dataIsLoaded = report?.xAxisValues && report?.yAxisSeries

    if (report && dataIsLoaded && reportMetadata && !reportTooMuchDataError) {
        const [records, columns] = getGridFormattedReportData(report, reportMetadata)

        const exportColumnsRegularGrid = columns.map<ExcelExportColumnProps>((col) => ({
            field: col.field,
            title: col.title,
        }))

        // Update records array to format number column to 1 decimal place
        records.forEach((col) => {
            const columnNames = Object.keys(col)
            // iterate over each column as the column name is dynamic
            // and we don't know the column name beforehand
            columnNames.forEach((colNameItem) => {
                // Get the column value
                const column: any = col[colNameItem]
                // check if a number using classic js function isNaN
                // and if the column is a decimal using modulus operator
                // eslint-disable-next-line no-restricted-globals
                if (!isNaN(column) && column % 1 !== 0) {
                    // update existing value to 1 decimal
                    col[colNameItem] = Number(col[colNameItem]).toFixed(1)
                    // eslint-disable-next-line no-restricted-globals
                } else if (!isNaN(column)) {
                    col[colNameItem] = Number(col[colNameItem])
                } else {
                    const newValue = col[colNameItem]
                    col[colNameItem] = newValue
                }
            })
            return col
        })

        const defaultChartHeight = contentHeight / 2.05
        let chartHeight = defaultChartHeight
        const verticalPane1Size = splitterPanes[0].size
        if (verticalPane1Size) {
            chartHeight = parseInt(verticalPane1Size)
        }

        // calculate the grid height based on
        const gridHeight = contentHeight - chartHeight - PaddingBetweenChartAndGrid - (drilldownData ? 10 : 0)

        // report grid or drilldown
        const gridContent = (
            <>
                {/* drilldown grid */}
                {drilldownData && (
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                marginTop: '5px',
                                marginBottom: '5px',
                            }}
                        >
                            <IconButton
                                tooltip="Export to Excel"
                                onClick={() => {
                                    exportRef.current!.save()
                                }}
                                icon="bi-file-earmark-arrow-down"
                            />
                            <IconButtonColumns
                                onClick={() => {
                                    setShowGridColumnPicker(true)
                                }}
                            />
                        </div>
                        <KendoGridCustom
                            centeredContent
                            localStorageKeyForColumnState="reportViewGrid"
                            localStorageKeyForGridDataState="reportViewGridForDataState"
                            showColumnPicker={showGridColumnPicker}
                            onColumnPickerHide={() => {
                                setShowGridColumnPicker(false)
                            }}
                            // height={`${componentHeight}px`}
                            height={`${gridHeight}px`}
                            columns={drilldownColumns}
                            data={drilldownData}
                            filterable={false}
                            pageable={props.isPrintPreview !== true}
                            noSelection
                            selectedRowsState={{}}
                            onSetSelectedRowsState={() => {}}
                            setColumnVisibility={(newColumnState: KendoGridColumn[]) =>
                                setDrilldownColumns(newColumnState)
                            }
                            excelExportRef={exportRef}
                            excelExportFilename={report.name}
                            excelExportColumns={exportFormatColumns}
                        />
                    </div>
                )}

                {/* regular grid */}
                {!drilldownData && (
                    <>
                        {!props.isPrintPreview && (
                            <div className="d-flex justify-content-between">
                                <div>
                                    <small>Click a point in the chart to drill down the data in the table.</small>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        marginTop: '2px',
                                        marginBottom: '3px',
                                    }}
                                >
                                    <IconButton
                                        tooltip="Export to Excel"
                                        onClick={() => {
                                            exportRefRegularGrid.current!.save()
                                        }}
                                        icon="bi-file-earmark-arrow-down"
                                    />
                                </div>
                            </div>
                        )}
                        <KendoGridCustom
                            centeredContent
                            localStorageKeyForColumnState="reportDrilldownGrid"
                            localStorageKeyForGridDataState="reportDrildownGridDataState"
                            height={`${gridHeight}px`}
                            columns={columns}
                            data={records}
                            pageable={false}
                            filterable={false}
                            noSelection
                            selectedRowsState={{}}
                            onSetSelectedRowsState={() => {}}
                            setColumnVisibility={() => {}}
                            excelExportRef={exportRefRegularGrid}
                            excelExportFilename={report.name}
                            excelExportColumns={exportColumnsRegularGrid}
                        />
                    </>
                )}
            </>
        )

        const reportContentStyle: CSSProperties = {}
        if (props.isPrintPreview) {
            reportContentStyle.width = '1000px'
        }

        if (!isRefreshing) {
            reportContent = (
                <Splitter
                    orientation="vertical"
                    panes={splitterPanes}
                    style={reportContentStyle}
                    onChange={(e: SplitterOnChangeEvent) => {
                        const splitterStateWithMaximums = [...e.newState]
                        splitterStateWithMaximums[0].max = `${contentHeight - GridMinHeight}px`
                        // capture the percent height of the top splitter pane so we can keep it during window resize
                        const chartPaneHeight = parseFloat(e.newState[0].size!.replaceAll('px', ''))
                        setChartPaneHeightPercent(chartPaneHeight / contentHeight)
                        setSplitterPanes(splitterStateWithMaximums)
                    }}
                >
                    <ReportChart
                        noTitle
                        chartHeight={chartHeight}
                        report={report}
                        metadata={reportMetadata}
                        drilldownSelection={drilldownSelection}
                        setDrilldownSelection={setDrilldownSelection}
                    />
                    {gridContent}
                </Splitter>
            )
        }
    }

    return (
        <>
            {dialogMode === 'ConfirmSaveToLibrary' && report && (
                <ConfirmSaveLibraryItem
                    itemName={report.name}
                    itemTypeName="Report"
                    closeCallback={() => {
                        setDialogMode('None')
                    }}
                    confirmedCallback={async (name) => {
                        await saveToLibrary('ConfirmedName', name)
                    }}
                />
            )}

            {drilldownError && (
                <SimpleDialog headerText="Drill-Down Not Available" closeCallback={() => setDrilldownError(null)}>
                    {drilldownError}
                </SimpleDialog>
            )}

            <GridPageLayout
                breadcrumbs={breadCrumbs}
                headingContent={report?.name || ''}
                buttons={toolbarButtons}
                onMainContentHeightChange={(newHeight: number) => {
                    setContentHeight(newHeight)
                    setSplitterPanes((previous) => {
                        const updated = [...previous]
                        const splitterChartSizeString = updated[0].size
                        if (splitterChartSizeString) {
                            // we want to maintain a constant splitter size ratio if the user has adjusted it
                            const newSplitterHeightFromPercent = newHeight * chartPaneHeightPercent
                            updated[0].size = `${newSplitterHeightFromPercent}px`
                        }
                        return updated
                    })
                }}
            >
                {reportTooMuchDataError || reportContent}
            </GridPageLayout>
        </>
    )
}

export default ReportView
