import { toast } from 'react-toastify'
import { addNewDataSourceToTemplate } from 'store/actions/templateActions'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import OperationModes from 'types/OperationModes'
import { Scenario } from 'types/Scenario'
import IconButton from 'views/Common/Buttons/IconButton'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import FormSection from 'views/Common/Form/FormSection'
import ScenarioDataSourceOverridesPanel from 'views/Scenarios/Components/ScenarioDataSourceOverridesPanel'
import ProfileDataSourceDetailsPanel from '../ParameterPanels/ProfileDataSourceDetails/ProfileDataSourceDetailsPanel'
import ProfileExpansionPanel from './ProfileExpansionPanel'

interface DataSourcesSelectorPanelProps {
    scenario?: Scenario
    validated?: boolean
    onFileChange?: (event: React.ChangeEvent<HTMLInputElement>, profileDataSourceId: string, fileIndex: number) => void
    onOverrideChange?: (event: FormControlChangeEvent, profileDataSourceId: string) => void
}

const DataSourcesSelectorPanel = ({
    scenario,
    validated,
    onFileChange,
    onOverrideChange,
}: DataSourcesSelectorPanelProps) => {
    const dispatch = useAppDispatch()
    const template = useAppSelector((rs) => rs.profile.template)

    const hasDataSources = template && template.profileDataSourceIds && template.profileDataSourceIds.length > 0

    return (
        <>
            <FormSection title="Data Sources" padding={hasDataSources ? 0 : undefined}>
                {template &&
                    template.profileDataSourceIds &&
                    template.profileDataSourceIds.length > 0 &&
                    template.profileDataSourceIds.map((profileId, index) => (
                        <>
                            <ProfileExpansionPanel
                                dataSourceId={profileId}
                                key={index}
                                type="DataSource"
                                onDelete={(id: string) => {
                                    dispatch(profileActions.removeDataSourceFromTemplate(id))
                                }}
                            >
                                <ProfileDataSourceDetailsPanel
                                    id={profileId}
                                    operationMode={OperationModes.Edit}
                                    validatedForm={true}
                                    enableCommonFields={false}
                                />
                            </ProfileExpansionPanel>
                            {scenario && (
                                <ScenarioDataSourceOverridesPanel
                                    profileId={profileId}
                                    dataSourceOverride={scenario.scenarioDataSourceOverrides.find(
                                        (override) => override.profileDataSourceId === profileId,
                                    )}
                                    validated={validated}
                                    onFileChange={onFileChange!}
                                    onOverrideChange={onOverrideChange!}
                                />
                            )}
                        </>
                    ))}
                {!hasDataSources && (
                    <IconButton
                        text="Add Data Source"
                        tooltip="Add"
                        icon="bi-file-plus"
                        onClick={async () => {
                            const added = await dispatch(addNewDataSourceToTemplate())
                            if (!added) {
                                toast.error('No Data Source Profiles found')
                            }
                        }}
                        style={{ marginTop: 10 }}
                    />
                )}
            </FormSection>
        </>
    )
}

export default DataSourcesSelectorPanel
