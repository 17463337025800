import useGridLayout from 'hooks/useGridLayout'
import { useCallback, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { getColumnsFromGridLayout } from 'services/utilities/kendoGridColumnsHelpers'
import { getFilterFields } from 'services/utilities/kendoGridUtils'
import { handleApiError } from 'services/utilities/toastrUtils'
import gridLayoutActions from 'store/actions/gridLayoutActions'
import { RootState, useAppDispatch } from 'store/store'
import { KendoGridColumn } from 'types/GridLayout'
import { defaultUserEditable, User, UserEditable } from 'types/interfaces'
import MetaData from 'types/Metadata'
import OperationModes from 'types/OperationModes'
import EllipsisDropdown, { EllipsisDropdownItem, ItemWithIcon } from 'views/Common/Buttons/EllipsisDropdown'
import IconButton from 'views/Common/Buttons/IconButton'
import IconButtonFilter from 'views/Common/Buttons/IconButtonFilter'
import SeperatorVertical from 'views/Common/Buttons/SeparatorVertical'
import ConfirmationDialog from 'views/Common/GenericDialogs/ConfirmationDialog'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import GridPageLayout from 'views/Common/Layout/PageLayout'
import { formatOperation } from 'views/viewUtils'
import UserDetails from './UserDetails'

type DialogMode = 'None' | 'AddUser' | 'EditUser' | 'ConfirmDeactivate' | 'ConfirmActivate' | 'ConfirmResetPassword'

const Users = () => {
    const [editUser, setEditUser] = useState<UserEditable>(defaultUserEditable)
    const [operationMode, setOperationMode] = useState<OperationModes>(OperationModes.Add)
    const [addEditUserComponent, setAddEditUserComponent] = useState(false)

    const [dialogMode, setDialogMode] = useState<DialogMode>('None')
    const [atUserLimit, setAtUserLimit] = useState(false)
    const [users, setUsers] = useState<User[]>([])
    const [gridHeight, setGridHeight] = useState(100)
    const metadata = useSelector<RootState, MetaData>((x) => x.app.metadata!)
    const [userCustomFields, setUserCustomFields] = useState('')
    const dispatch = useAppDispatch()
    const [activeGridLayout] = useGridLayout('users')
    const enableFiltering = activeGridLayout?.configurationJson.filtersEnabled || false
    const filteredFields = getFilterFields(activeGridLayout?.configurationJson?.dataState?.filter?.filters || [])

    useEffect(() => {
        document.title = 'SAFTE-FAST - Users'
    })

    const getColumnsHelper = useCallback(
        () =>
            getColumnsFromGridLayout({
                gridLayout: activeGridLayout,
                usersGridOptions: {
                    customFields: userCustomFields,
                    userClicked: (user: User) => {
                        setEditUser(user)
                        setOperationMode(OperationModes.Edit)
                        setAddEditUserComponent(true)
                    },
                },
            }),
        [activeGridLayout, userCustomFields],
    )

    const [stateColumns, setStateColumns] = useState<Array<KendoGridColumn>>(() => getColumnsHelper())
    const [reloadDataFlag, setReloadDataFlag] = useState<Date | null>(null)
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const selectedIds = getSelectedIds(selectedRowsState)
    const api = globals.getApi()

    useEffect(() => {
        const loadData = async () => {
            try {
                const data = await api.getUsers()
                setUsers(data)
                const customFields = await api
                    .getSystemSettingApi()
                    .getSettingValue('Setting.General.Users.CustomFields')
                setUserCustomFields(customFields)
                const licenseData = await api.getActiveUsersAndLicenseLimit()
                if (licenseData != null) {
                    // Variable that defines how many actives users this tenant currently has
                    const activeUsersCount = licenseData.activeUsersCount

                    // Variable that defines how many users this tenant has been allotted.
                    const activeUsersLimit = licenseData.activeUsersLimit

                    if (activeUsersCount === activeUsersLimit) {
                        showLicenseWarningBanner(
                            `You have reached the maximum number of active (or pending) users in the system for your license ( ${activeUsersLimit} ).`,
                        )
                    } else if (activeUsersCount > activeUsersLimit) {
                        showLicenseWarningBanner(
                            `You have ${activeUsersCount} active (or pending) users in the system, but your license only grants you ${activeUsersLimit}.  Please disable some users to comply with your license agreement.`,
                        )
                    }

                    if (activeUsersCount >= activeUsersLimit) {
                        setAtUserLimit(true)
                    } else {
                        setAtUserLimit(false)
                    }
                }
            } catch (err: any) {
                handleApiError(err)
            }
        }
        loadData()
    }, [api, reloadDataFlag])

    // update the columns when metadata is loaded in
    useEffect(() => {
        const columns = getColumnsHelper()
        setStateColumns(columns)
    }, [setStateColumns, metadata, enableFiltering, getColumnsHelper])

    function showLicenseWarningBanner(text: string) {
        toast.error(text, {
            closeButton: true,
            autoClose: 8000,
            pauseOnHover: true,
            position: 'top-center',
        })
    }

    const usersGrid = users && (
        <KendoGridCustom
            gridLayoutName="users"
            height={`${gridHeight}px`}
            data={users}
            dataState={activeGridLayout?.configurationJson?.dataState}
            columnMenuFiltering={enableFiltering}
            columns={stateColumns}
            selectedRowsState={selectedRowsState}
            onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
            setColumnVisibility={(newColumnState: KendoGridColumn[]) => setStateColumns(newColumnState)}
        />
    )

    const toolbarButtons = (
        <>
            <IconButton
                tooltip={!atUserLimit ? 'Add User' : 'Cannot activate any additional users'}
                disabled={atUserLimit}
                onClick={() => {
                    setOperationMode(OperationModes.Add)
                    setAddEditUserComponent(true)
                }}
                icon="bi-file-plus"
            />
            <IconButton
                tooltip={!atUserLimit ? 'Activate User(s)' : 'Cannot activate any additional users'}
                disabled={selectedIds.length === 0 || atUserLimit}
                onClick={() => {
                    setDialogMode('ConfirmActivate')
                }}
                style={{ fontSize: '20px' }}
                icon="bi-person-check"
                toolbarLeftMargin
            />
            <IconButton
                tooltip="Deactivate User(s)"
                disabled={selectedIds.length === 0}
                onClick={() => {
                    setDialogMode('ConfirmDeactivate')
                }}
                style={{ fontSize: '20px' }}
                icon="bi-person-x"
                toolbarLeftMargin
            />
            <IconButton
                tooltip="Reset Password for User(s)"
                disabled={selectedIds.length === 0}
                onClick={() => {
                    setDialogMode('ConfirmResetPassword')
                }}
                icon="bi-key"
                toolbarLeftMargin
            />
            <SeperatorVertical />

            <IconButtonFilter
                filterEnabled={enableFiltering}
                filterIsActive={enableFiltering && filteredFields.length > 0}
                onClick={() => dispatch(gridLayoutActions.setGridFilteringEnabled('users', !enableFiltering))}
            />

            <EllipsisDropdown>
                <EllipsisDropdownItem
                    disabled={selectedIds.length === 0 || atUserLimit}
                    onClick={() => {
                        setOperationMode(OperationModes.Add)
                        setAddEditUserComponent(true)
                    }}
                >
                    <ItemWithIcon bootstrapIconClass="bi-file-plus">Add User</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem
                    disabled={selectedIds.length === 0 || atUserLimit}
                    onClick={() => setDialogMode('ConfirmActivate')}
                >
                    <ItemWithIcon bootstrapIconClass="bi-person-check">Activate User(s)</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem
                    disabled={selectedIds.length === 0}
                    onClick={() => setDialogMode('ConfirmDeactivate')}
                >
                    <ItemWithIcon bootstrapIconClass="bi-person-x">Deactivate User(s)</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem
                    disabled={selectedIds.length === 0}
                    onClick={() => setDialogMode('ConfirmResetPassword')}
                >
                    <ItemWithIcon bootstrapIconClass="bi-key">Reset Password for User(s)</ItemWithIcon>
                </EllipsisDropdownItem>
                <Dropdown.Divider />

                <EllipsisDropdownItem
                    onClick={() => dispatch(gridLayoutActions.setGridFilteringEnabled('users', !enableFiltering))}
                >
                    <ItemWithIcon bootstrapIconClass="bi-funnel">
                        {enableFiltering ? 'Disable Column Filters' : 'Enable Column Filters'}
                    </ItemWithIcon>
                </EllipsisDropdownItem>
            </EllipsisDropdown>
        </>
    )

    const getSelectedUsers = () => selectedIds.map((id) => users.find((u) => u.id === Number(id))!)

    const getSelectedUsersAsListItems = () => (
        <ul>
            {getSelectedUsers().map((user, index) => (
                <li key={index}>{user.loginId}</li>
            ))}
        </ul>
    )

    const updateUserState = (updatedUser: User) => {
        setUsers((previous) => {
            return [
                ...previous.map((u) => {
                    return u.id === updatedUser.id ? updatedUser : u
                }),
            ]
        })
    }

    const addEditUserDetailsPageCallback = useCallback(
        (result: DialogResultEnum, updatedUser: UserEditable) => {
            if (result === DialogResultEnum.Completed && updatedUser) {
                setEditUser(defaultUserEditable)
                const successMessage = 'The user has been ' + formatOperation(operationMode)
                toast.success(successMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                })
                setReloadDataFlag(new Date())
            }
            setAddEditUserComponent(false)
            setEditUser(defaultUserEditable)
        },
        [operationMode],
    )

    return (
        <>
            {addEditUserComponent ? (
                <UserDetails
                    setEditUser={setEditUser}
                    user={editUser}
                    operationMode={operationMode}
                    onCloseComponent={addEditUserDetailsPageCallback}
                    userCustomFields={userCustomFields}
                />
            ) : (
                <>
                    <GridPageLayout
                        headingContent="Manage Users"
                        buttons={toolbarButtons}
                        onMainContentHeightChange={setGridHeight}
                    >
                        {usersGrid}
                    </GridPageLayout>
                    {(dialogMode === 'ConfirmDeactivate' || dialogMode === 'ConfirmActivate') && (
                        <ConfirmationDialog
                            headerText="Modify Users"
                            confirmedCallback={async () => {
                                setReloadDataFlag(new Date())
                                getSelectedUsers().forEach(async (user) => {
                                    user.state = dialogMode === 'ConfirmActivate' ? 'Active' : 'Deactivated'
                                    updateUserState(await api.updateUser(user))
                                })
                                setDialogMode('None')
                            }}
                            closeCallback={() => setDialogMode('None')}
                        >
                            <>
                                <p>
                                    Are you sure you want to{' '}
                                    {dialogMode === 'ConfirmActivate' ? 'activate' : 'deactivate'} the selected users?
                                </p>
                                {getSelectedUsersAsListItems()}
                            </>
                        </ConfirmationDialog>
                    )}
                    {dialogMode === 'ConfirmResetPassword' && (
                        <ConfirmationDialog
                            headerText="Send User Password Reset Email(s)"
                            confirmedCallback={async () => {
                                getSelectedUsers().forEach(async (user) => {
                                    await api.passwordResetRequest(user.loginId)
                                })
                                toast.success('Password reset emails have been sent')
                                setDialogMode('None')
                            }}
                            closeCallback={() => setDialogMode('None')}
                        >
                            <>
                                <p>
                                    Are you sure you want to send an email to reset the password for the selected
                                    user(s)?
                                </p>
                                {getSelectedUsersAsListItems()}
                            </>
                        </ConfirmationDialog>
                    )}
                </>
            )}
        </>
    )
}

export default Users
