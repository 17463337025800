import { TagKeyValue } from 'types/ScheduleEvent'
import { Permission, SleepQualityEnum } from './interfaces'

export type ScenarioSaveOptions = 'save' | 'new'

export interface Scenario {
    id: number
    name: string
    description?: string
    templateId: string
    templateName: string
    scenarioTags: TagKeyValue[]
    scenarioDataSourceOverrides: ScenarioDataSourceOverride[]
}

export interface ScenarioDataSourceOverride {
    id: string
    scenarioId: number
    profileDataSourceId: string
    fileName1: string
    fileName2: string
    file1: File | null
    file2: File | null
    year: number
    periodDateStart: Date
    filterDateStart: Date
    filterDateEnd: Date
    fleet: string
}

export interface ScenarioMetrics {
    minutes: number
    minutesCrewing: number
    minutesCritical: number
    effectivenessAvg: number
    effectivenessMax: number
    effectivenessMin: number
    effectivenessCrewingAvg: number
    effectivenessCrewingMax: number
    effectivenessCrewingMin: number
    effectivenessCriticalAvg: number
    effectivenessCriticalMax: number
    effectivenessCriticalMin: number
    kssCriticalMax: number
    samnPerelliCriticalMax: number
    reservoirAvg: number
    reservoirMax: number
    reservoirMin: number
    reservoirCrewingAvg: number
    reservoirCrewingMax: number
    reservoirCrewingMin: number
    reservoirCriticalAvg: number
    reservoirCriticalMax: number
    reservoirCriticalMin: number
    minutesBelowCriterion: number
    minutesBelowCriterionCrewing: number
    minutesBelowCriterionCritical: number
    percentBelowCriterion: number
    percentBelowCriterionCrewing: number
    percentBelowCriterionCritical: number
    numberofExcessivePBCEvents: number
    numberofExcessivePBCEventsCrewing: number
    numberofExcessivePBCEventsCritical: number
    totalFatigueHazardArea: number
    totalFatigueHazardAreaCrewing: number
    totalFatigueHazardAreaCritical: number
    percentFatigueHazardArea: number
    percentFatigueHazardAreaCrewing: number
    percentFatigueHazardAreaCritical: number
    workloadMax: number
    workloadMed: number
}

interface AllPieGraphDatum {
    chartTitle: string
    category: string
    value: number
    color: string
    explode: boolean
}

export interface ScenarioParameters {
    awakeZoneStart: string
    awakeZoneEnd: string
    bedtime: string
    commuteType: 'FixedBuffer' | 'VariableEvents'
    eventLabel: string
    maxWorkDaySleep: number
    maxRestDaySleep: number
    minimumSleep: number
    commute: number
    criterionLevel: number
    percentBelowCriterionThreshold: number
    editSleep: boolean
    autoNap: boolean
    plannedWorkSleep: boolean
    plannedWorkSleepAutoSleep: boolean
    ignoreExplicitSleep: boolean
    plannedWorkSleepRules: PlannedWorkSleepRule[]
}

export interface ScenarioDetail {
    numberOfSchedules: number
    hasFailedSchedules?: boolean
    hasAllFailedSchedules?: boolean
    allPieGraphData?: AllPieGraphDatum[][]
    hasCrewingData: boolean
    hasCriticalData: boolean
    id: number
    name: string
    scenarioOwnerName: string
    createdById: number
    metrics: ScenarioMetrics
    modifiedDate: Date
    permission: Permission
    errors: string[]
    warning: string[]
    scenarioParameters: ScenarioParameters
    allNonCrewing?: boolean
}

export interface PlannedWorkSleepRule {
    id: number
    quality: SleepQualityEnum
}

export function parseScenarioParameters(data: any): ScenarioParameters {
    return {
        awakeZoneStart: '1300',
        awakeZoneEnd: '2000',
        bedtime: '2300',
        maxWorkDaySleep: 480,
        maxRestDaySleep: 540,
        minimumSleep: 60,
        commute: 60,
        criterionLevel: 77.0,
        percentBelowCriterionThreshold: 20.0,
        editSleep: false,
        autoNap: true,
        plannedWorkSleep: true,
        plannedWorkSleepAutoSleep: true,
        commuteType: 'VariableEvents',
        eventLabel: 'Flight',
        ignoreExplicitSleep: false,
        plannedWorkSleepRules: [],
    }

    // return {
    //     awakeZoneStart: String(data.awakeZoneStart),
    //     awakeZoneEnd: String(data.awakeZoneEnd),
    //     bedtime: String(data.bedtime),
    //     commuteType: data.commuteType,
    //     eventLabel: String(data.eventLabel),

    //     maxWorkDaySleep: Number(data.maxWorkDaySleep),
    //     maxRestDaySleep: Number(data.maxRestDaySleep),
    //     minimumSleep: Number(data.minimumSleep),
    //     commute: Number(data.commute),
    //     criterionLevel: Number(data.criterionLevel),
    //     percentBelowCriterionThreshold: Number(data.percentBelowCriterionThreshold),

    //     editSleep: Boolean(data.editSleep),
    //     autoNap: Boolean(data.autoNap),
    //     plannedWorkSleep: Boolean(data.plannedWorkSleep),
    //     plannedWorkSleepAutoSleep: Boolean(data.plannedWorkSleepAutoSleep),
    //     ignoreExplicitSleep: Boolean(data.ignoreExplicitSleep),

    //     plannedWorkSleepRules: ((data.plannedWorkSleepOutputRuleIds || []) as any[]).map((x) => {
    //         const values = x.split('-')
    //         const qualityDescription: 'Excellent' | 'Good' | 'Fair' | 'Poor' = values[1]
    //         const quality: SleepQualityEnum = SleepQualityEnum[qualityDescription]
    //         return {
    //             id: parseInt(values[0]),
    //             quality,
    //         }
    //     }),
    // }
}

/**
 * Convert from any to Scenario
 * @param data
 * @returns
 */
export function parseScenario(data: any): ScenarioDetail {
    return {
        numberOfSchedules: Number(data.numberOfSchedules),
        hasFailedSchedules: Boolean(data.hasFailedSchedules),
        hasAllFailedSchedules: Boolean(data.hasAllFailedSchedules),
        hasCrewingData: Boolean(data.hasCrewingData),
        hasCriticalData: Boolean(data.hasCriticalData),
        id: Number(data.id),
        name: String(data.name),
        scenarioOwnerName: String(data.scenarioOwnerName),
        createdById: Number(data.createdById),
        metrics: data.metrics,
        modifiedDate: new Date(Date.parse(data.modifiedDate)),
        permission: data.permission,
        errors: data.errors as Array<string>,
        warning: data.warning as Array<string>,
        scenarioParameters: parseScenarioParameters(data.scenarioParameters),
        allNonCrewing: data.allNonCrewing,
        allPieGraphData: data.allPieGraphData,
    }
}
