import React, { MouseEventHandler } from 'react'
import { fetchAndSetTemplateFromId } from 'store/actions/templateActions'
import { useAppDispatch, useAppSelector } from 'store/store'
import IconButton from 'views/Common/Buttons/IconButton'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import TemplateProfileExpansionPanel from './TemplateProfileExpansionPanel'
import TemplateSelectorTool from './TemplateSelectorTool'

interface Props {
    onChange: (e: FormControlChangeEvent) => void
    onDelete?: MouseEventHandler<HTMLButtonElement>
    children: React.ReactNode
}

const TemplateExpansionPanel = ({ children, onDelete, onChange }: Props) => {
    const dispatch = useAppDispatch()
    const template = useAppSelector((rs) => rs.profile.template)

    const custom = Boolean(template && template.libraryId)

    // todo: onSave
    const onSave = () => {}

    const onReset = () => {
        if (template && template.libraryId) {
            onChange({
                target: { name: 'templateId', value: template.libraryId },
            })
            dispatch(fetchAndSetTemplateFromId(template.libraryId!))
        }
    }

    const selectorTool = (
        <TemplateSelectorTool
            onSave={onSave}
            onSelected={(selectedTemplateId) => {
                onChange({
                    target: { name: 'templateId', value: selectedTemplateId },
                })
                dispatch(fetchAndSetTemplateFromId(selectedTemplateId))
            }}
        />
    )

    const deleteButton = onDelete && (
        <IconButton tooltip="Delete" toolbarLeftMargin icon="bi-trash" onClick={onDelete} />
    )

    return (
        <TemplateProfileExpansionPanel
            selectorTool={selectorTool}
            custom={custom}
            onSave={onSave}
            onReset={onReset}
            deleteButton={deleteButton}
            isOpenDefault
        >
            {children}
        </TemplateProfileExpansionPanel>
    )
}

export default TemplateExpansionPanel
