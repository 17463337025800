/**
 * Responsible for talking to the back-end API (web or SFC)
 */

import SessionControl from 'services/axios/SessionControl'
import { AuthToken } from 'types/AuthToken'
import { EventFilterConfig } from 'types/EventFilterConfig'
import { GridLayout, GridLayoutJson } from 'types/GridLayout'
import { AccountLicense, FileProcessing, parseFileProcessing, parseUser, User, UserEditable } from 'types/interfaces'
import MetaData, { parseMetadata } from 'types/Metadata'
import { ReportItemDefinitionDataType } from 'types/ReportingMetadata'
import { ShareType, ShareUsersConfiguration } from 'types/ShareUsersConfiguration'
import { parseShift, PatternsShifts } from 'types/Shifts'
import Axios from '../axios/axios-sfc'
import * as sessionToken from './sessionToken'
import SFApiBase from './sfApiBase'
import SFProcessingApi from './sfProcessingApi'
import SFProfileApi from './sfProfileApi'
import SFReportingApi from './sfReportingApi'
import SFScenarioApi from './sfScenarioApi'
import SFScheduleApi from './sfScheduleApi'
import SFStationsApi from './sfStationsApi'
import SFSystemSettingApi from './sfSystemSettingApi'
import SFTagsApi from './sfTagsApi'
import SFTemplateApi from './sfTemplateApi'
// import { UpdateDutyRequest } from 'types/UpdateDutyRequest'
// import { UpdateDutyTimeRequest } from 'types/UpdateDutyTimeRequest'

type UpdatePatternsShifts = Omit<PatternsShifts, 'shiftTypeList'>

class SFApi extends SFApiBase {
    private readonly reportingApi: SFReportingApi
    private readonly stationsApi: SFStationsApi
    private readonly processingApi: SFProcessingApi
    private readonly systemSettingsApi: SFSystemSettingApi
    private readonly profileApi: SFProfileApi
    private readonly templateApi: SFTemplateApi
    private readonly sessionControl: SessionControl
    private readonly scenarioApi: SFScenarioApi
    private readonly scheduleApi: SFScheduleApi
    private readonly tagsApi: SFTagsApi

    constructor(private axios: Axios) {
        super()
        this.axios = axios
        this.sessionControl = axios.getSessionControl()
        this.reportingApi = new SFReportingApi(this.axios)
        this.stationsApi = new SFStationsApi(this.axios)
        this.processingApi = new SFProcessingApi(this.axios)
        this.systemSettingsApi = new SFSystemSettingApi(this.axios)
        this.profileApi = new SFProfileApi(this.axios)
        this.templateApi = new SFTemplateApi(this.axios)
        this.scenarioApi = new SFScenarioApi(this.axios)
        this.scheduleApi = new SFScheduleApi(this.axios)
        this.tagsApi = new SFTagsApi(this.axios)
    }

    getReportingApi = () => this.reportingApi
    getStationsApi = () => this.stationsApi
    getProcessingApi = () => this.processingApi
    getSystemSettingApi = () => this.systemSettingsApi
    getProfileApi = () => this.profileApi
    getTemplateApi = () => this.templateApi
    getScenarioApi = () => this.scenarioApi
    getScheduleApi = () => this.scheduleApi
    getTagsApi = () => this.tagsApi

    updateUser = async (user: UserEditable): Promise<User> => {
        const data = (await this.axios.put(this.baseUrl + '/Api/Users/', user, this.getConfig())).data
        return parseUser(data)
    }

    createUser = async (user: UserEditable): Promise<User> => {
        const data = (await this.axios.post(this.baseUrl + '/Api/Users/', user, this.getConfig())).data
        return parseUser(data)
    }

    passwordResetRequest = async (loginId: string): Promise<void> => {
        const formData = new FormData()
        formData.append('loginId', loginId)
        await this.axios.post(this.baseUrl + '/Api/Users/PasswordResetRequest', formData, this.getConfig())
    }

    validatePasswordResetToken = async (token: string): Promise<void> => {
        await this.axios.get(this.baseUrl + `/Api/Users/PasswordReset?tokenValue=${token}`, this.getConfig())
    }

    setNewPassword = async (token: string, password1: string, password2: string): Promise<void> => {
        const formData = new FormData()
        formData.append('token', token)
        formData.append('pass1', password1)
        formData.append('pass2', password2)
        await this.axios.post(this.baseUrl + '/Api/Users/PasswordReset', formData, this.getConfig())
    }

    /**
     * Get PatternsShifts for the dialog.
     * @returns
     */
    getPatternsShifts = async (): Promise<PatternsShifts> => {
        const patternsShifts = (await this.axios.get(this.baseUrl + '/Api/Shifts/GetPatternsShifts/', this.getConfig()))
            .data as PatternsShifts
        patternsShifts.patterns.forEach((pattern) => {
            pattern.shifts = pattern.shifts.map((shift) => {
                return parseShift(shift)
            })
        })
        patternsShifts.shiftSegments = patternsShifts.shiftSegments.map((shiftSeg) => parseShift(shiftSeg))
        return patternsShifts
    }

    updatePatternsShifts = async (patternsShifts: UpdatePatternsShifts): Promise<PatternsShifts> => {
        await this.axios.post(this.baseUrl + '/Api/Shifts/UpdatePatternsShifts', patternsShifts, this.getConfig())
        // api should be fixed to actually return the updated object with proper ids, but since it doesn't, just call again.
        return this.getPatternsShifts()
    }

    getUsers = async (): Promise<Array<User>> => {
        const data = (await this.axios.get(this.baseUrl + '/Api/Users', this.getConfig())).data as Array<any>
        return data.map<User>(parseUser)
    }

    getUserSharing = async (itemIds: number[], itemType: ShareType): Promise<ShareUsersConfiguration> => {
        const data = (
            await this.axios.get(
                this.baseUrl + `/Api/Sharing/ItemShareUsers?itemIds=${itemIds.join(',')}&itemType=${itemType}`,
                this.getConfig(),
            )
        ).data
        return data
    }

    updateUserSharing = async (request: ShareUsersConfiguration): Promise<void> => {
        await this.axios.post(this.baseUrl + '/Api/Sharing/UpdateUserSharing/', request, this.getConfig())
    }

    getFileProcessingRecords = async (scenarioId?: number): Promise<Array<FileProcessing>> => {
        let url = this.baseUrl + '/Api/FileProcessing/'
        if (scenarioId) {
            url += scenarioId.toString()
        }
        const data = (await this.axios.get(url, this.getConfig())).data as Array<any>
        return data.map<FileProcessing>(parseFileProcessing)
    }

    getMetadata = async (): Promise<MetaData> => {
        const data = (await this.axios.get(this.baseUrl + '/Api/Metadata', this.getConfig())).data
        return parseMetadata(data)
    }

    updateGridLayout = async (gridLayoutId: string, gridLayoutJson: string): Promise<void> => {
        await this.axios.put(
            `${this.baseUrl}/Api/GridLayouts/Update/${gridLayoutId}`,
            { gridLayoutJson },
            this.getConfig(),
        )
    }

    saveGridLayoutAs = async (gridLayoutId: string, newName: string): Promise<void> => {
        await this.axios.post(
            `${this.baseUrl}/Api/GridLayouts/SaveAs`,
            { originalId: gridLayoutId, name: newName },
            this.getConfig(),
        )
    }

    setActiveGridLayout = async (gridLayoutId: string): Promise<void> => {
        await this.axios.post(`${this.baseUrl}/Api/GridLayouts/Activate/${gridLayoutId}`, {}, this.getConfig())
    }

    deleteGridLayout = async (gridLayoutId: string): Promise<void> => {
        await this.axios.delete(`${this.baseUrl}/Api/GridLayouts/Delete/${gridLayoutId}`, {}, this.getConfig())
    }

    getGridLayouts = async (): Promise<GridLayout[]> => {
        const data = (await this.axios.get(this.baseUrl + '/Api/GridLayouts', this.getConfig())).data

        const gridLayouts = (data as any[]).map((x: any) => {
            const config = JSON.parse(x.configurationJson) as GridLayoutJson
            return {
                ...x,
                configurationJson: config,
            } as GridLayout
        })

        return gridLayouts
    }

    getAppVersion = async (): Promise<string> => {
        try {
            return (await this.axios.get(this.baseUrl + '/Api/Metadata/AppVersion', this.getConfig())).data
        } catch {
            return ''
        }
    }

    getActiveUsersAndLicenseLimit = async (): Promise<AccountLicense> => {
        return (await this.axios.get(this.baseUrl + '/Api/Users/ActiveUsersAndLicenseLimit', this.getConfig())).data
    }

    signOut = async (): Promise<void> => {
        try {
            const toke = sessionToken.getSessionToken()
            this.sessionControl.setIsLoggedIn(false)
            sessionToken.deleteSessionToken()
            if (toke) {
                await this.axios.post(
                    this.baseUrl + '/Api/Users/LoggedOut',
                    {},
                    {
                        headers: {
                            Authorization: toke,
                        },
                    },
                )
            }
        } catch (err) {
            if (!String(err).includes('Your session in SAFTE-FAST has expired')) {
                throw err
            }
        }
    }

    authenticateCredentials = async (
        emailAddress: string,
        password: string,
        keepMeLoggedIn: boolean,
    ): Promise<AuthToken> => {
        try {
            const formData = new FormData()
            formData.append('email', emailAddress)
            formData.append('pass', password)
            formData.append('keepMeLoggedIn', keepMeLoggedIn.toString())
            const response = await this.axios.post(
                this.baseUrl + '/Api/Users/AuthenticateCredentials',
                formData,
                this.getConfig(),
            )

            const authToken = response.data as AuthToken
            sessionToken.setSessionToken(authToken.tokenValue)
            this.sessionControl.setIsLoggedIn(true)
            return authToken
        } catch (err) {
            sessionToken.deleteSessionToken()
            throw err
        }
    }

    authenticateToken = async (token: string): Promise<AuthToken | false> => {
        try {
            const tokenObject = await (
                await this.axios.get(this.baseUrl + `/Api/Users/AuthenticateToken?token=${token}`, this.getConfig())
            ).data
            this.sessionControl.setIsLoggedIn(true)
            return tokenObject
        } catch (error) {
            sessionToken.deleteSessionToken()
            return false
        }
    }

    getEventsFilterConfig = async (): Promise<EventFilterConfig> => {
        const config = (await this.axios.get(this.baseUrl + '/Api/Parameters/EventsFilterConfig', this.getConfig()))
            .data as EventFilterConfig
        config.configItems.forEach((x) => {
            x.type = x.type.toLowerCase() as ReportItemDefinitionDataType
        })
        return config
    }
}

export default SFApi
