import { Col, Form, Row } from 'react-bootstrap'
import { SystemSetting } from 'types/SystemSetting'
import FormControlNumericInput from 'views/Common/Form/FormControls/FormControlNumericInput'
import FormRow from 'views/Common/Form/FormRows/FormRow'
import FormSection from 'views/Common/Form/FormSection'
import ColorPaletteRow from './ColorPaletteRow'

interface Props {
    settings: SystemSetting[]
    setSettings: (settings: SystemSetting[]) => void
    onChange: (setting: SystemSetting) => void
}
const PalettesForm = ({ settings, setSettings, onChange }: Props) => {
    async function handleInputChange(name: string, value: string) {
        const updatedSettings = settings.map((element) => (element.name === name ? { ...element, value } : element))
        setSettings([...updatedSettings])
        onChange?.(updatedSettings.find((setting) => setting.name === name)!)
    }

    return (
        <FormSection>
            <FormRow
                labelText="Effectiveness"
                subText="Ranges and colors used to display effectiveness scores. Criterion will be referenced from the Metrics profile"
                labelColWidth={100}
            />
            <div style={{ marginLeft: 50, marginRight: 50 }}>
                <Form.Group className="mb-3">
                    <Row noGutters style={{ maxWidth: '100%' }}>
                        <Col style={{ paddingTop: '8px' }}>
                            <span>0</span>
                        </Col>
                        <Col style={{ paddingLeft: '0' }}>
                            <span>
                                <FormControlNumericInput
                                    value={Number(settings[4].value)}
                                    id={settings[4].name}
                                    onChange={(e) =>
                                        handleInputChange(
                                            settings[4].name,
                                            e.target.value === null ? '0' : e.target.value.toString(),
                                        )
                                    }
                                    style={{ width: '75px' }}
                                    min={0}
                                    max={100}
                                />
                            </span>
                        </Col>
                        <Col style={{ paddingLeft: 0, paddingTop: '8px' }}>
                            <span>Criterion</span>
                        </Col>
                        <Col
                            style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <span style={{ textAlign: 'left' }}>
                                <FormControlNumericInput
                                    value={Number(settings[5].value)}
                                    id={settings[5].name}
                                    onChange={(e) =>
                                        handleInputChange(
                                            settings[5].name,
                                            e.target.value === null ? '0' : e.target.value.toString(),
                                        )
                                    }
                                    style={{ width: '75px' }}
                                    min={0}
                                    max={100}
                                />
                            </span>
                            <span style={{ textAlign: 'right', paddingTop: '8px' }}>100</span>
                        </Col>
                    </Row>
                </Form.Group>

                <ColorPaletteRow settings={settings.slice(0, 4)} handleInputChange={handleInputChange} />
            </div>

            <FormRow
                labelText="Reservoir"
                subText="Ranges and colors used to display sleep reservoir scores"
                labelColWidth={100}
            />
            <div style={{ marginLeft: 50, marginRight: 50 }}>
                <Form.Group className="mb-3">
                    <Row noGutters style={{ maxWidth: '100%' }}>
                        <Col style={{ paddingTop: '8px' }}>
                            <span>0</span>
                        </Col>
                        <Col style={{ paddingLeft: '0' }}>
                            <span>
                                <FormControlNumericInput
                                    value={Number(settings[10].value)}
                                    id={settings[10].name}
                                    onChange={(e) =>
                                        handleInputChange(
                                            settings[10].name,
                                            e.target.value === null ? '0' : e.target.value.toString(),
                                        )
                                    }
                                    style={{ width: '75px' }}
                                    min={0}
                                    max={100}
                                />
                            </span>
                        </Col>
                        <Col style={{ paddingLeft: 0, paddingTop: '8px' }}>
                            <span>75</span>
                        </Col>
                        <Col
                            style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <span style={{ textAlign: 'left' }}>
                                <FormControlNumericInput
                                    value={Number(settings[11].value)}
                                    id={settings[11].name}
                                    onChange={(e) =>
                                        handleInputChange(
                                            settings[11].name,
                                            e.target.value === null ? '0' : e.target.value.toString(),
                                        )
                                    }
                                    style={{ width: '75px' }}
                                    min={0}
                                    max={100}
                                />
                            </span>
                            <span style={{ textAlign: 'right', paddingTop: '8px' }}>100</span>
                        </Col>
                    </Row>
                </Form.Group>

                <ColorPaletteRow settings={settings.slice(6, 10)} handleInputChange={handleInputChange} />
            </div>

            <FormRow
                labelText="% Below Criterion"
                subText="Ranges and colors used to display %BC scores. Threshold will be referenced from the Metrics profile"
                labelColWidth={100}
            />
            <div style={{ marginLeft: 50, marginRight: 50 }}>
                <Form.Group className="mb-3">
                    <Row noGutters style={{ maxWidth: '100%' }}>
                        <Col style={{ paddingTop: '8px' }}>
                            <span>0</span>
                        </Col>
                        <Col style={{ paddingLeft: '0' }}>
                            <span>
                                <FormControlNumericInput
                                    value={Number(settings[16].value)}
                                    id={settings[16].name}
                                    onChange={(e) =>
                                        handleInputChange(
                                            settings[16].name,
                                            e.target.value === null ? '0' : e.target.value.toString(),
                                        )
                                    }
                                    style={{ width: '75px' }}
                                    min={0}
                                    max={100}
                                />
                            </span>
                        </Col>
                        <Col style={{ paddingLeft: 0 }}>
                            <span>
                                <FormControlNumericInput
                                    value={Number(settings[17].value)}
                                    id={settings[17].name}
                                    onChange={(e) =>
                                        handleInputChange(
                                            settings[17].name,
                                            e.target.value === null ? '0' : e.target.value.toString(),
                                        )
                                    }
                                    style={{ width: '75px' }}
                                    min={0}
                                    max={100}
                                />
                            </span>
                        </Col>
                        <Col
                            style={{
                                paddingTop: '8px',
                                paddingLeft: 0,
                                paddingRight: 0,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <span style={{ textAlign: 'left' }}>Threshold</span>
                            <span style={{ textAlign: 'right' }}>100</span>
                        </Col>
                    </Row>
                </Form.Group>
                <ColorPaletteRow settings={settings.slice(12, 16)} handleInputChange={handleInputChange} />
            </div>
        </FormSection>
    )
}

export default PalettesForm
