import { BaseDeterminationOnImportTypeNames, getRecordKeyValues, ScheduleTypeNames } from 'types/EnumTypes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { InputNumericFormRow, InputSelectFormRow, InputTextFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import NonCrewingEventsParameters from './NonCrewingEventsParameters'

interface DataSourceJeppesenProps {
    onChange: (e: FormControlChangeEvent) => void
    profile: ProfileDataSource
    validatedForm: boolean
}
const DataSourceJeppesen = (props: DataSourceJeppesenProps) => {
    return (
        <>
            <InputSelectFormRow
                labelText="Schedule Type"
                subText="The types of schedules to load into a scenario"
                value={props.profile.scheduleType}
                onChange={props.onChange}
                fieldId="scheduleType"
                options={getRecordKeyValues(ScheduleTypeNames)}
            />

            <InputSelectFormRow
                labelText="Determine Base Station From"
                subText="How schedules' base station is set"
                value={props.profile.determineBaseStationFrom}
                onChange={props.onChange}
                fieldId="determineBaseStationFrom"
                options={getRecordKeyValues(BaseDeterminationOnImportTypeNames)}
            />

            <NonCrewingEventsParameters
                profile={props.profile}
                onChange={props.onChange}
                validatedForm={props.validatedForm}
            />

            <InputNumericFormRow
                labelText="Schedule Year"
                subText="Sets event dates to the specified year"
                value={props.profile.scheduleYear}
                onChange={props.onChange}
                fieldId="scheduleYear"
                minValue={0}
                invalidText="Enter a value of 0 or greater"
            />

            {props.profile.scheduleType !== 'Pairings' ? (
                <InputTextFormRow
                    labelText="Fleet"
                    subText="Filters rosters to the specified fleet"
                    value={props.profile.fleet}
                    onChange={props.onChange}
                    fieldId="fleet"
                />
            ) : null}
        </>
    )
}

export default DataSourceJeppesen
