import OperationModes from '../types/OperationModes'

export const formatTitle = (operation: OperationModes, entity: string): string => {
    switch (operation) {
        case OperationModes.Edit:
            return `Edit ${entity}`
        case OperationModes.Copy:
            return `Copy ${entity}`
        default:
            return `Add ${entity}`
    }
}

export const formatOperation = (operation?: OperationModes): string => {
    switch (operation) {
        case OperationModes.Edit:
            return 'Edited'
        case OperationModes.Copy:
            return 'Copied'
        default:
            return 'Added'
    }
}
