import { ChangeEvent, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { BaseInputProps, buildEventNumeric, FormControlChangeEvent } from './FormControlBase'

export interface FormControlNumericInputProps extends BaseInputProps {
    value?: number
    onChange: (e: FormControlChangeEvent) => void
    min?: number
    max?: number
    step?: any
    validated?: boolean
    style?: React.CSSProperties
}

const FormControlNumericInput = (props: FormControlNumericInputProps) => {
    const [localValue, setLocalValue] = useState<string>(props.value?.toString() || '')
    const [hasBeenTouched, setHasBeenTouched] = useState(false)
    const min = props.min || 0

    useEffect(() => {
        // update local value if prop value changed externally (ie. reset custom profile)
        setLocalValue(props.value?.toString() || '')
    }, [props.value])

    const isInvalid =
        props.required &&
        hasBeenTouched &&
        (Number.isNaN(parseFloat(localValue)) ||
            (props.min !== undefined && parseFloat(localValue) < props.min) ||
            (props.max !== undefined && parseFloat(localValue) > props.max))

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setLocalValue(value) // Update the displayed value

        // Use buildEventNumeric to process the input and notify parent
        const event = buildEventNumeric(e)
        props.onChange(event)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setHasBeenTouched(true)

        // Use buildEventNumeric to finalize the value and notify parent
        const event = buildEventNumeric({
            target: e.target as HTMLInputElement,
        } as ChangeEvent<HTMLInputElement>) // Manually cast the event type for buildEventNumeric

        const numericValue = parseFloat(event.target.value)

        if (Number.isNaN(numericValue)) {
            setLocalValue('') // Clear invalid input
            props.onChange({ ...event, target: { name: event.target.name, value: null } }) // Notify parent with null
        } else {
            setLocalValue(numericValue.toString()) // Normalize valid input
            props.onChange(event) // Notify parent with the valid event
        }
    }

    return (
        <>
            <Form.Control
                style={props.style}
                role="spinbutton"
                aria-label={props.id}
                type="number" // Use number to maintain numeric input behavior
                value={localValue} // Bind to string-based local state
                pattern="[-]?[0-9]*[.]?[0-9]*" // Regex pattern for validation
                onChange={handleInputChange}
                onBlur={handleBlur}
                id={props.id}
                name={props.id}
                min={min}
                {...(props.step && { step: props.step })}
                {...(props.max && { max: props.max })}
                required={props.required}
                isInvalid={isInvalid && props.validated} // Dynamic validation
            />
            {isInvalid && props.invalidText && (
                <Form.Control.Feedback type="invalid">{props.invalidText}</Form.Control.Feedback>
            )}
        </>
    )
}

export default FormControlNumericInput
