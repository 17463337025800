import { useState } from 'react'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import { CommuteTypeNames, DutyBreakTypeNames, getRecordKeyValues, IndustryTypeNames } from 'types/EnumTypes'
import OperationModes from 'types/OperationModes'
import { FormControlChangeEvent, updateObjectWithFormEvent } from 'views/Common/Form/FormControls/FormControlBase'
import {
    DrillDownFormRow,
    InputNumericFormRow,
    InputSelectFormRow,
    InputTextFormRow,
} from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import IndentedBlock from 'views/Common/Layout/IndentedBlock'
import ProfileCommonFields from '../ProfileCommonFields'
import BreakWithSleepParameters from './BreakWithSleepParameters'
import BufferTypeEventsParameters from './BufferTypeEventsParameters'
import BufferTypeFixedParameters from './BufferTypeFixedParameters'
import EventDurationRulesDialog from './EventDurationRulesDialog'

interface WorkParametersProps {
    validatedForm: boolean
    operationMode: OperationModes
    enableCommonFields: boolean
}
const ProfileWorkDetailsPanel = (props: WorkParametersProps) => {
    const dispatch = useAppDispatch()
    const [showEventDurationsDialog, setShowEventDurationsDialog] = useState<boolean>(false)
    const profile = useAppSelector((state) => state.profile.profileWork)
    if (!profile) return null

    const toggleDialog = () => {
        setShowEventDurationsDialog(!showEventDurationsDialog)
    }

    const onChange = (e: FormControlChangeEvent) => {
        const updated = updateObjectWithFormEvent(profile, e)
        dispatch(profileActions.setProfileByType({ profile: updated, type: 'Work', isModified: true }))
    }

    return (
        <>
            {props.enableCommonFields && (
                <FormSection title="Details">
                    <ProfileCommonFields
                        nameField={profile.name}
                        descriptionField={profile.description}
                        onChange={onChange}
                    />
                </FormSection>
            )}

            <FormSection title={props.enableCommonFields ? 'Parameters' : null}>
                <InputTextFormRow
                    labelText="Default Base Station"
                    subText="The station used when creating new schedules"
                    value={profile.defaultBaseStation}
                    onChange={onChange}
                    fieldId="defaultBaseStation"
                    invalidText="Enter an IATA or ICAO station code"
                    required
                />

                <InputSelectFormRow
                    labelText="Industry Nomenclature"
                    subText="How auto-events should be labeled"
                    value={profile.industry}
                    onChange={onChange}
                    fieldId="industry"
                    options={getRecordKeyValues(IndustryTypeNames)}
                />

                <IndentedBlock headingText="Duty Events" subheadingText="Optional Events that wrap work events">
                    <InputNumericFormRow
                        labelText="Brief Duration"
                        subText="An event that occurs before the first work"
                        value={profile.briefDuration}
                        onChange={onChange}
                        fieldId="briefDuration"
                        minValue={0}
                        invalidText="Enter a value of 0 or greater"
                        required={true}
                    />

                    <InputNumericFormRow
                        labelText="Debrief Duration"
                        subText="An event that occurs after the last work"
                        value={profile.debriefDuration}
                        onChange={onChange}
                        fieldId="debriefDuration"
                        minValue={0}
                        invalidText="Enter a value of 0 or greater"
                        required={true}
                    />
                </IndentedBlock>

                <InputSelectFormRow
                    labelText="Duty Break"
                    subText="How to handle the gap between work events"
                    value={profile.dutyBreakType}
                    onChange={onChange}
                    fieldId="dutyBreakType"
                    options={getRecordKeyValues(DutyBreakTypeNames)}
                />

                {profile.dutyBreakType === 'SplitDutySleep' ? (
                    <BreakWithSleepParameters
                        onChange={onChange}
                        profile={profile}
                        validatedForm={props.validatedForm}
                    />
                ) : null}

                <IndentedBlock headingText="Duty Buffers" subheadingText="Optional events that wrap duty events">
                    <InputSelectFormRow
                        labelText="Buffer Type"
                        subText="How duties should be buffered from sleep"
                        value={profile.bufferType}
                        onChange={onChange}
                        fieldId="bufferType"
                        options={getRecordKeyValues(CommuteTypeNames)}
                    />

                    {profile.bufferType === 'VariableEvents' ? (
                        <BufferTypeEventsParameters
                            onChange={onChange}
                            profile={profile}
                            validatedForm={props.validatedForm}
                        />
                    ) : (
                        <BufferTypeFixedParameters onChange={onChange} profile={profile} />
                    )}
                </IndentedBlock>

                <DrillDownFormRow
                    labelText="Event Duration Override Rules"
                    subText="Conditionally override duration of duty and buffer events"
                    data={profile.eventDurationRules}
                    operationMode={props.operationMode}
                    onClick={toggleDialog}
                    value={`${profile.eventDurationRules.length} Rule${
                        profile.eventDurationRules.length === 1 ? '' : 's'
                    }`}
                />
            </FormSection>

            {showEventDurationsDialog && <EventDurationRulesDialog onClose={toggleDialog} />}
        </>
    )
}

export default ProfileWorkDetailsPanel
