import React from 'react'
import ScheduleDetail from 'types/Schedule'
import ScheduleEvent, { ScheduleEventRecord } from 'types/ScheduleEvent'

export type ScheduleDetailsState = {
    setFormState: (event: ScheduleEventRecord | null) => void
    getFormState: () => ScheduleEventRecord | null
    setSchedule: (schedule: ScheduleDetail) => void
    setChangedGridRowIndex: (rowIndex: number) => void
    setDeleteEvents: (scheduleEvents: ScheduleEvent[]) => void
    addNewEventViaDialog: (scheduleEvent: ScheduleEvent) => void
}

const ScheduleDetailsContext = React.createContext<ScheduleDetailsState>({} as ScheduleDetailsState)

export default ScheduleDetailsContext
