import DataCategoryLabel from 'views/Reports/Page/ReportConfigurationTypes'
import { HazardClassDialogConfig } from './HazardClass'
import { Recalculatable } from './Recalculatable'
import { ScenarioSelection } from './ScenarioSelection'

export type DrilldownSelection = {
    from: number
    to: number
    values: string[]
}

export type ReportDialogConfig = Pick<
    Report,
    | 'id'
    | 'name'
    | 'createdByName'
    | 'chartType'
    | 'colorAssignmentMode'
    | 'isLibraryReport'
    | 'dashboardOrder'
    | 'includeAllMyScenarios'
    | 'includeAllScenariosSharedwMe'
    | 'selectedScenarioIds'
    | 'overlayScenarios'
    | 'overrideDashboardScenarioSelection'
    | 'showAxisTitles'
    | 'showLegend'
    | 'showValueLabels'
    | 'showXAxisLabels'
    | 'showYAxisLabels'
    | 'showHorizontalGridLines'
    | 'xAxisLabel'
    | 'yAxisLabel'
    | 'type'
    | 'configOptions'
    | 'createdById'
    | 'shares'
    | 'permission'
>

export const getNewReportSeries = (otherSeries: Series[], color: string): Series => {
    const seriesId = otherSeries.length === 0 ? 1 : Math.max(...otherSeries.map((x) => x.seriesId)) + 1
    return {
        id: 0,
        scenarioId: 0,
        seriesId,
        configurableReportOptionsId: 0,
        dataItem: '',
        aggregation: '',
        isChildSeries: false,
        color,
        plotType: 'column',
        visible: true,
    }
}

export const getNewReport = (): ReportDialogConfig => {
    const newReport: ReportDialogConfig = {
        id: 0,
        name: '',
        createdByName: '',
        chartType: 'column',
        colorAssignmentMode: 'SimplePalette',
        dashboardOrder: 0,
        includeAllMyScenarios: true,
        includeAllScenariosSharedwMe: true,
        selectedScenarioIds: '',
        overlayScenarios: true,
        overrideDashboardScenarioSelection: false,
        showAxisTitles: true,
        showLegend: true,
        showValueLabels: false,
        showXAxisLabels: true,
        showYAxisLabels: true,
        showHorizontalGridLines: true,
        isLibraryReport: false,
        type: 'ReportConfigurable',
        configOptions: {
            binSize: 0,
            dataType: 'schedules',
            id: 0,
            limitResults: 10,
            orderDirection: 'Asc',
            orderItem: 'ID',
            series: [
                {
                    id: 0,
                    configurableReportOptionsId: 0,
                    seriesId: 1,
                    isChildSeries: false,
                    scenarioId: 0,
                    dataItem: DataCategoryLabel,
                    aggregation: 'Count',
                    color: '',
                    plotType: 'column',
                    visible: true,
                },
            ],
            xAxisGrouping: 'None',
            xAxisItem: 'ID',
        },
        permission: {
            isSharedThroughChildItem: false,
            itemId: 0,
            amOwnerOfSharedItem: false,
        },
    }
    return newReport
}

export const getScenarioSelection = (report: ReportDialogConfig | HazardClassDialogConfig): ScenarioSelection => {
    return {
        includeAllMyScenarios: report.includeAllMyScenarios,
        includeAllScenariosSharedwMe: report.includeAllScenariosSharedwMe,
        selectedScenarioIds: report.selectedScenarioIds,
    }
}

export const getReportUsedColorsString = (report: Report) => {
    let usedColorsString = ''
    if (report.configOptions) {
        usedColorsString = report.configOptions.series
            .filter((s) => s.isChildSeries !== true)
            .map((s) => s.color)
            .join(',')
    }
    return usedColorsString
}

export interface Report extends Recalculatable {
    id: number
    name: string
    overrideDashboardScenarioSelection: boolean
    showAxisTitles: boolean
    showXAxisLabels: boolean
    showYAxisLabels: boolean
    showLegend: boolean
    includeAllMyScenarios: boolean
    includeAllScenariosSharedwMe: boolean
    selectedScenarioIds: string
    showHorizontalGridLines: boolean
    showValueLabels: boolean
    /**
     * chartType is used by WT Eff Report (hard coded legacy report); regular custom reports use plotType at the series level
     */
    chartType: string
    overlayScenarios: boolean
    dashboardOrder: number
    yAxisLabel?: string
    xAxisLabel?: string
    colorAssignmentMode: 'SimplePalette' | 'GradientPalette'
    configOptions?: ConfigOption

    accountId: number
    createdById?: number
    createdByName: string
    isLibraryReport: boolean
    includeAsDefault: boolean
    // requiresRecalculation: boolean
    type: string
    seriesColors?: string
    hasConfigOptions: boolean
    xAxisValues: string[] | null
    yAxisSeries: YAxisSeries[] | null
    permission: Permission
    shares?: any
}

interface Permission {
    itemId: number
    amOwnerOfSharedItem: boolean
    isSharedThroughChildItem: boolean
    sharedWithMePermission?: any
}

export interface YAxisSeries {
    seriesId: number
    labels: Labels
    data?: (number | null | string)[]
    // data?: ((null | number)[] | number)[][]
}

interface Labels {
    visible: boolean
}

export interface ConfigOption {
    id: number
    series: Series[]
    xAxisGrouping: string
    xAxisItem: string
    orderItem: string
    dataType: 'schedules' | 'events'
    queryBuilderRules?: QueryBuilderValue
    orderDirection: 'Asc' | 'Desc' | 'None'
    limitResults: number
    binSize: number
}

export type RuleOrQueryBuilder = Rule | QueryBuilderValue

/**
 * This is the format used in the original jquery Query Builder component,
 * and also used by the DynamicLinqQueryBuilder on the c# side
 */
export interface QueryBuilderValue {
    condition: string
    rules: RuleOrQueryBuilder[]
    valid: boolean
}

export interface Rule {
    id: string
    field: string
    type: string
    input: string
    operator: string
    value: (boolean | number)[]
}

export interface Series {
    id: number
    configurableReportOptionsId: number
    seriesId: number
    isChildSeries: boolean
    scenarioId: number
    dataItem: string
    aggregation: string
    color: string
    plotType: ReportChartPlotType
    visible: boolean
}

export type ReportChartPlotType = 'column' | 'line'

export const parseReport = (data: any): Report => {
    return {
        ...data,
        // id: parseInt(data.id),
        // name: String(data.name),
        // xAxisValues: data.xAxisValues,
    }
}
