export type ProfileModuleTypes = 'Work' | 'Sleep' | 'Markers' | 'Metrics' | 'Workload' | 'DataSource'
export const ProfileModuleTypeNames: Record<ProfileModuleTypes, string> = {
    Work: 'ProfileWork',
    Sleep: 'ProfileAutoSleep',
    Markers: 'ProfileAutoMarker',
    Metrics: 'ProfileMetricsConfiguration',
    Workload: 'ProfileWorkload',
    DataSource: 'ProfileDataSource',
}

export type IndustryTypes = 'Aviation' | 'Rail' | 'Shift' | 'Maritime' | 'Unknown'
export const IndustryTypeNames: Record<IndustryTypes, string> = {
    Aviation: 'Aviation',
    Rail: 'Rail',
    Shift: 'Shift',
    Maritime: 'Maritime',
    Unknown: 'Unknown',
}
export type DutyBreakTypes = 'Sit' | 'SplitDutySleep' | 'OpenTime'
export const DutyBreakTypeNames: Record<DutyBreakTypes, string> = {
    Sit: 'Break',
    SplitDutySleep: 'Break with Sleep',
    OpenTime: 'Open Time',
}

export type CommuteTypes = 'FixedBuffer' | 'VariableEvents'
export const CommuteTypeNames: Record<CommuteTypes, string> = {
    FixedBuffer: 'Fixed Buffers',
    VariableEvents: 'Events',
}

export type SplitDutyLocationTypes = 'Hotel' | 'RestFacility' | 'LongestSleep' | 'HighestQuality'
export const SplitDutyLocationTypeNames: Record<SplitDutyLocationTypes, string> = {
    Hotel: 'Hotel',
    RestFacility: 'Rest Facility',
    LongestSleep: 'Longest Sleep',
    HighestQuality: 'Highest Quality',
}

export type AutoSleepModeTypes = 'ModifiedLocal' | 'ModifiedLocalAway' | 'Local' | 'Base'
export const AutoSleepModeTypeNames: Record<AutoSleepModeTypes, string> = {
    ModifiedLocal: 'Modified Local',
    ModifiedLocalAway: 'Modified Local - Away',
    Local: 'Local',
    Base: 'Base',
}

export type SleepQualityTypes = 'Excellent' | 'Good' | 'Fair' | 'Poor' | 'Default'
export const SleepQualityTypeNames: Record<SleepQualityTypes, string> = {
    Excellent: 'Excellent',
    Good: 'Good',
    Fair: 'Fair',
    Poor: 'Poor',
    Default: 'Default',
}

export type ZoneTypes = 'UTC' | 'Local' | 'Base'
export const ZoneTypeNames: Record<ZoneTypes, string> = {
    UTC: 'UTC',
    Local: 'Local',
    Base: 'Base',
}

export type OverlapTypes = 'Any' | 'CompleteEvent' | 'CompleteRange' | 'StartOfEvent' | 'EndOfEvent'
export const OverlapTypeNames: Record<OverlapTypes, string> = {
    Any: 'Any',
    CompleteEvent: 'Complete Event',
    CompleteRange: 'Complete Range',
    StartOfEvent: 'Start of Event',
    EndOfEvent: 'End of Event',
}

export type CrewingTypes = 'Crewing' | 'NonCrewing' | 'Marker' | 'CriticalMarker' | 'AnyWork' | 'AnyMarker'
export const CrewingTypeNames: Record<CrewingTypes, string> = {
    Crewing: 'Crewing',
    NonCrewing: 'Non-Crewing',
    Marker: 'Marker',
    CriticalMarker: 'Critical Marker',
    AnyWork: 'Any Work',
    AnyMarker: 'Any Marker',
}

export type CriticalTimeTypes = 'EventStartEnd' | 'NightTimeRange'
export const CriticalTimeTypeNames: Record<CriticalTimeTypes, string> = {
    EventStartEnd: 'Event Start/End',
    NightTimeRange: 'Night Time Range',
}

export type WorkloadFactorTypes = 'Time00' | 'Time06' | 'Time12' | 'Time18' | 'SeasonNov' | 'SeasonMay' | 'Custom'
export const WorkloadFactorTypeNames: Record<WorkloadFactorTypes, string> = {
    Time00: 'Time 00-06',
    Time06: 'Time 06-12',
    Time12: 'Time 12-18',
    Time18: 'Time 18-00',
    SeasonNov: 'Season Nov-Apr',
    SeasonMay: 'Season May-Oct',
    Custom: 'Custom',
}

export type WeightTypes = 'VeryLow' | 'Low' | 'Medium' | 'High' | 'VeryHigh'
export const WeightTypeNames: Record<WeightTypes, string> = {
    VeryLow: 'Very Low',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High',
    VeryHigh: 'Very High',
}

export type OffsetUnitTypes = 'Minutes' | 'Percent'
export const OffsetUnitTypeNames: Record<OffsetUnitTypes, string> = {
    Minutes: 'Minutes',
    Percent: 'Percent',
}

export type DurationUnitTypes = 'Minutes' | 'Percent'
export const DurationUnitTypeNames: Record<DurationUnitTypes, string> = {
    Minutes: 'Minutes',
    Percent: 'Percent',
}

export type EventTriggerReferenceTypes = 'BeforeStart' | 'AfterStart' | 'BeforeEnd' | 'AfterEnd'
export const EventTriggerReferenceTypeNames: Record<EventTriggerReferenceTypes, string> = {
    BeforeStart: 'Before Start',
    AfterStart: 'After Start',
    BeforeEnd: 'Before End',
    AfterEnd: 'After End',
}

export type PriorWorkTypes = 'AnyWork' | 'Crewing' | 'NonCrewing'
export const PriorWorkTypeNames: Record<PriorWorkTypes, string> = {
    AnyWork: 'Any Work',
    Crewing: 'Crewing',
    NonCrewing: 'Non-Crewing',
}

export type InputTypes =
    | 'SAFTEFASTCSV'
    | 'SAFTEFASTXML'
    | 'AltitudePairings'
    | 'CrewControlPairings'
    | 'CrewControlCSV'
    | 'CrewTracPRG'
    | 'CrewTracLIN'
    | 'FASPairings'
    | 'FOSPairings'
    | 'Fujitsu'
    | 'JeppesenCtf'
    | 'Jeppesen'
    | 'Kronos'
    | 'S3RUS'
    | 'Zulu'
export const InputTypeNames: Record<InputTypes, string> = {
    SAFTEFASTCSV: 'SAFTE FAST CSV',
    SAFTEFASTXML: 'SAFTE FAST XML',
    AltitudePairings: 'Altitude Pairings',
    CrewControlPairings: 'Crew Control Pairings',
    CrewControlCSV: 'Crew Control Rosters',
    CrewTracPRG: 'Crew Trac PRG',
    CrewTracLIN: 'Crew Trac LIN',
    FASPairings: 'FAS Pairings',
    FOSPairings: 'FOS Pairings',
    Fujitsu: 'Fujitsu',
    JeppesenCtf: 'Jeppesen CTF',
    Jeppesen: 'Jeppesen',
    Kronos: 'Kronos',
    S3RUS: 'S3RUS',
    Zulu: 'Zulu',
}

export type LabelFilterTypes = 'IncludeAll' | 'ExcludeAll'
export const LabelFilterTypeNames: Record<LabelFilterTypes, string> = {
    IncludeAll: 'Include all, Except',
    ExcludeAll: 'Exclude all, Except',
}

export type FilterTypes = 'IncludeAll' | 'IncludeSchedules' | 'IgnoreSchedules'
export const BasesFilterTypeNames: Record<FilterTypes, string> = {
    IncludeAll: 'Include All',
    IncludeSchedules: 'Include Schedules Based In',
    IgnoreSchedules: 'Ignore Schedules Based In',
}
export const LocationFilterTypeNames: Record<FilterTypes, string> = {
    IncludeAll: 'Include All',
    IncludeSchedules: 'Include Events In',
    IgnoreSchedules: 'Ignore Events In',
}
export const EquipmentFilterTypeNames: Record<FilterTypes, string> = {
    IncludeAll: 'Include All',
    IncludeSchedules: 'Include Events Using',
    IgnoreSchedules: 'Ignore Events Using',
}
export const KronosLocationFilterTypeNames: Record<FilterTypes, string> = {
    IncludeAll: 'Include All',
    IncludeSchedules: 'Include Pairings In',
    IgnoreSchedules: 'Ignore Pairings in',
}

export type EventTypeCreationTypes = 'AsEvents' | 'AsMarkers'
export const EventTypeCreationTypeNames: Record<EventTypeCreationTypes, string> = {
    AsEvents: 'as Events, Except',
    AsMarkers: 'as Markers, Except',
}

export type RosterStageTypes = 'ActualTimes' | 'PlannedTimes'
export const RosterStageTypeNames: Record<RosterStageTypes, string> = {
    ActualTimes: 'Actual Times',
    PlannedTimes: 'Planned Times',
}

export type BaseDeterminationOnImportTypes = 'StartStation' | 'DataFile' | 'BaseLocationParameter'
export const BaseDeterminationOnImportTypeNames: Record<BaseDeterminationOnImportTypes, string> = {
    StartStation: 'Start Station',
    DataFile: 'Data File',
    BaseLocationParameter: 'Base Location Parameter',
}

// Pairings, Rosters, PairingsAndRosters
export type ScheduleTypes = 'Pairings' | 'Rosters' | 'PairingsAndRosters'
export const ScheduleTypeNames: Record<ScheduleTypes, string> = {
    Pairings: 'Pairings',
    Rosters: 'Rosters',
    PairingsAndRosters: 'Pairings & Rosters',
}

export type SleepRuleDurationTypes = 'FormulaCDP' | 'Fixed'
export const SleepRuleDurationTypeNames: Record<SleepRuleDurationTypes, string> = {
    FormulaCDP: 'FormulaCDP',
    Fixed: 'Fixed',
}

export function getRecordKeys<T extends string, TEnumValue extends string | number>(enumVariable: {
    [key in T]: TEnumValue
}) {
    return Object.keys(enumVariable) as Array<T>
}

export function getRecordKeyValues<T extends string, TEnumValue extends string | number>(enumVariable: {
    [key in T]: TEnumValue
}): { key: string; value: TEnumValue }[] {
    const keys = getRecordKeys(enumVariable)
    return keys.map((key) => ({ key, value: enumVariable[key] }))
}

export function getRecordValue<T extends string, TEnumValue extends string | number>(
    recordVariable: {
        [key in T]: TEnumValue
    },
    recordKey: string,
) {
    return recordVariable[recordKey as T]
}
