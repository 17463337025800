import { ReactNode } from 'react'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'

interface FormFooterProps {
    contentLeft?: ReactNode
    disabledSave?: boolean
    disabledCancel?: boolean
    onCancel: () => void
    onOk?: () => void
    customCancelButtonText?: string
}

const FormFooter = (props: FormFooterProps) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                maxWidth: '1200px',
                margin: 'auto',
                paddingRight: '20px',
            }}
        >
            <div style={{ flex: 1, paddingRight: '20px' }}>{props.contentLeft && <span>{props.contentLeft}</span>}</div>

            <div style={{ display: 'flex', gap: '5px' }}>
                <ButtonCustom
                    isLarge
                    type="submit"
                    variant="primary"
                    disabled={props.disabledSave}
                    onClick={() => props.onOk?.()}
                >
                    OK
                </ButtonCustom>
                <ButtonCustom
                    isLarge
                    disabled={props.disabledCancel}
                    toolbarLeftMargin
                    variant="secondary"
                    onClick={() => props.onCancel?.()}
                >
                    {props.customCancelButtonText || 'Cancel'}
                </ButtonCustom>
            </div>
        </div>
    )
}

export default FormFooter
