import { useSystemSettings } from 'hooks/useSettingValue'
import { FormEvent, useEffect, useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { palettesActions } from 'store/palettesStore'
import { useAppDispatch } from 'store/store'
import { SettingValue, SystemSetting } from 'types/SystemSetting'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'
import ConfirmationDialog from 'views/Common/GenericDialogs/ConfirmationDialog'
import PalettesForm from './PalettesForm'

type DialogMode = 'None' | 'SubmitConfirmation' | 'ResetConfirmation'

const PalettesPage = () => {
    const dispatch = useAppDispatch()
    const [settings, setSettings, resetSettings] = useSystemSettings('Palettes')
    const [isChanged, setIsChanged] = useState(false)
    const [validatedForm, setValidatedForm] = useState(false)
    const [dialogMode, setDialogMode] = useState<DialogMode>('None')

    const api = globals.getApi()

    useEffect(() => {
        document.title = 'SAFTE-FAST - Palettes'
    }, [])

    const handleOnChange = (setting: SystemSetting) => {
        const list = [...settings]
        const index = list.findIndex((s) => s.name === setting.name)
        if (index >= 0) {
            list[index].value = setting.value
            setSettings(list)
            setIsChanged(true)
        }
    }

    const submitHandler = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        let invalid = false

        if (form.checkValidity() === false) {
            setValidatedForm(true)
            invalid = true
        }

        if (invalid) {
            return
        }

        setDialogMode('SubmitConfirmation')
    }, [])

    const performSubmit = async () => {
        const settingValues: SettingValue[] = settings.map((item) => ({
            name: item.name,
            value: item.value,
        }))

        await api.getSystemSettingApi().updateSystemSettings(settingValues)
        dispatch(palettesActions.clearPalettes())

        setIsChanged(false)
        toast.success('General Settings have been Updated.')
        setDialogMode('None')
    }

    const handleReset = useCallback(() => {
        resetSettings()
        setIsChanged(false)
        setDialogMode('None')
    }, [resetSettings])

    const footer = (
        <FormFooter
            customCancelButtonText="Reset"
            disabledSave={!isChanged}
            disabledCancel={!isChanged}
            onCancel={() => setDialogMode('ResetConfirmation')}
        />
    )

    if (!settings || settings.length === 0) {
        return <p>Loading settings...</p>
    }

    return (
        <>
            <FormPage
                headingContent="Palettes"
                footerContent={footer}
                validatedForm={validatedForm}
                onSubmit={submitHandler}
            >
                <PalettesForm
                    key={JSON.stringify(settings)} // Force re-render on reset
                    settings={settings}
                    setSettings={setSettings}
                    onChange={handleOnChange}
                />
            </FormPage>

            {dialogMode === 'SubmitConfirmation' && (
                <ConfirmationDialog
                    headerText="Save Palette Settings"
                    closeCallback={() => setDialogMode('None')}
                    confirmedCallback={() => performSubmit()}
                >
                    <p>Are you sure you want to save Palette Settings?</p>
                </ConfirmationDialog>
            )}

            {dialogMode === 'ResetConfirmation' && (
                <ConfirmationDialog
                    headerText="Reset Palette Settings"
                    secondaryButtonTextOverride="Cancel"
                    closeCallback={() => setDialogMode('None')}
                    confirmedCallback={() => {
                        handleReset()
                    }}
                >
                    <p>Are you sure you want to reset your changes to Palette Settings?</p>
                </ConfirmationDialog>
            )}
        </>
    )
}

export default PalettesPage
