import { AxiosProgressEvent } from 'axios'
import useDetailPageParams from 'hooks/useDetailPageParams'
import { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { fetchAndSetTemplateFromId, saveScenarioAndUpdatedTemplate } from 'store/actions/templateActions'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import OperationModes from 'types/OperationModes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { Scenario, ScenarioDataSourceOverride } from 'types/Scenario'
import { TagKeyValue } from 'types/ScheduleEvent'
import { v4 as uuidv4 } from 'uuid'
import { FormControlChangeEvent, updateObjectWithFormEvent } from 'views/Common/Form/FormControls/FormControlBase'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'
import { InputTextAreaFormRow, InputTextFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ProgressBar from 'views/Common/Widget/ProgressBar'
import TagsTable from 'views/Common/Widget/TagsTable'
import DataSourcesSelectorPanel from 'views/Settings/Profiles/DataSourcesSelectorPanel'
import ProfilesSelectorPanel from 'views/Settings/Profiles/ProfilesSelectorPanel'
import TemplateExpansionPanel from 'views/Settings/Templates/TemplateExpansionPanel'
import { formatOperation, formatTitle } from 'views/viewUtils'
import ScenarioSaveConfirmationDialog from './ScenarioSaveConfirmationDialog'

const ScenarioDetailPage = () => {
    const { id, operationMode } = useDetailPageParams()

    const scenarioApi = globals.getApi().getScenarioApi()
    const tagsApi = globals.getApi().getTagsApi()
    const apiProfile = globals.getApi().getProfileApi()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(profileActions.clearProfilesAndTemplate()) // Reset Redux state
    }, [dispatch])

    const [scenario, setScenario] = useState<Scenario>()
    const [scenarioTagDefinitions, setScenarioTagDefinitions] = useState<string[]>([])
    const [scenarioModified, setScenarioModified] = useState(false)

    const template = useAppSelector((rs) => rs.profile.template)
    const templateChanged = useAppSelector((rs) => rs.profile.templateModified)

    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [validatedForm, setValidatedForm] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)

    const isDataSourceUpdating = scenario?.scenarioDataSourceOverrides?.some(
        (override) => override.file1 || override.file2,
    )

    const profileDataSourceId =
        template && template.profileDataSourceIds && template.profileDataSourceIds.length > 0
            ? template.profileDataSourceIds[0]
            : undefined

    const areTagsInValid = () => {
        const scenarioTags = scenario?.scenarioTags || []
        const anyNoNameTags = scenarioTags.filter((x) => x.name === '').length > 0
        const hasDuplicates = scenarioTags.length !== new Set(scenarioTags.map((x) => x.name)).size
        return anyNoNameTags || hasDuplicates
    }

    // Set the scenario state based on the operation mode
    useEffect(() => {
        const init = async () => {
            dispatch(profileActions.setScenarioMode(true))
            try {
                setScenarioTagDefinitions(await tagsApi.getScenarioTagDefinitions())
                if (operationMode === OperationModes.Edit) {
                    const res = await scenarioApi.getScenario(Number(id))
                    setScenario(res)
                    dispatch(fetchAndSetTemplateFromId(res.templateId))
                } else if (operationMode === OperationModes.Copy) {
                    const res = await scenarioApi.copyScenario(id)
                    setScenario(res)
                    dispatch(fetchAndSetTemplateFromId(res.templateId))
                    setScenarioModified(true) // copying a scenario results in it being 'modified'
                } else {
                    const res = await scenarioApi.getDefaultScenario()
                    setScenario(res)
                    dispatch(fetchAndSetTemplateFromId(res.templateId))
                }
            } catch (error: any) {
                toast.error(error.message)
            }
        }
        init()
    }, [scenarioApi, tagsApi, dispatch, id, operationMode])

    // Set up the overrides state
    useEffect(() => {
        if (!scenario || !template) return

        const updateOverridesState = async () => {
            const updatedScenario = { ...scenario }
            let hasChanges = false // Track if we need to update state

            // todo - will have to reimplement multi DS here

            // if the template does not have DS defined OR the profileDataSourceId has been undefined
            if (
                (!template.profileDataSourceIds || template.profileDataSourceIds.length <= 0 || !profileDataSourceId) &&
                updatedScenario.scenarioDataSourceOverrides.length !== 0
            ) {
                // clear scneario overrides
                updatedScenario.scenarioDataSourceOverrides = []
                hasChanges = true
            } else {
                // setup scenario overrides
                const existingOverride = updatedScenario.scenarioDataSourceOverrides.find(
                    (override) => override.profileDataSourceId === profileDataSourceId,
                )

                // if no override for the selected profile, add one
                if (!existingOverride && profileDataSourceId) {
                    // get the profile so we can set defaults
                    const profileDataSource = (await apiProfile.getProfile(
                        profileDataSourceId,
                        'DataSource',
                    )) as ProfileDataSource

                    // Create a new override if one does not exist
                    const newOverride: ScenarioDataSourceOverride = {
                        id: uuidv4(),
                        scenarioId: updatedScenario.id,
                        profileDataSourceId: profileDataSourceId!,
                        fileName1: '',
                        fileName2: '',
                        file1: null,
                        file2: null,
                        periodDateStart: profileDataSource.periodStart,
                        year: profileDataSource.scheduleYear,
                        filterDateStart: profileDataSource.dateStartFilter,
                        filterDateEnd: profileDataSource?.dateStartFilter,
                        fleet: profileDataSource?.fleet,
                    }
                    updatedScenario.scenarioDataSourceOverrides.push(newOverride)
                    hasChanges = true
                }

                // Remove missing overrides (from previous edits)
                const validOverrides = updatedScenario.scenarioDataSourceOverrides.filter((override) =>
                    template.profileDataSourceIds?.includes(override.profileDataSourceId),
                )
                if (validOverrides.length !== updatedScenario.scenarioDataSourceOverrides.length) {
                    updatedScenario.scenarioDataSourceOverrides = validOverrides
                    hasChanges = true
                }
            }

            if (hasChanges) {
                setScenario(updatedScenario)
            }
        }

        updateOverridesState()
    }, [apiProfile, scenario, template, profileDataSourceId]) // Ensure only necessary dependencies are added

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>, dataSourceId: string, fileIndex: number) => {
        const files = event.target.files
        if (!files || files.length <= 0) return

        // update the scenarioOverrides
        const updatedScenario = { ...scenario } as Scenario
        const existingOverride = scenario?.scenarioDataSourceOverrides.find(
            (override) => override.profileDataSourceId === dataSourceId,
        )
        if (!existingOverride) return

        // todo - setting these properties dynamically is awkward - we should consider having an array of files instead
        if (fileIndex === 1) {
            existingOverride.fileName1 = files[0].name
            existingOverride.file1 = files[0]
        }
        if (fileIndex === 2) {
            existingOverride.fileName2 = files[0].name
            existingOverride.file2 = files[0]
        }

        setScenario(updatedScenario)
        setScenarioModified(true)
    }

    const onOverrideChange = (e: FormControlChangeEvent, dataSourceId: string) => {
        // update the scenarioOverrides
        const updatedScenario = { ...scenario } as Scenario
        const existingOverride = scenario?.scenarioDataSourceOverrides.find(
            (override) => override.profileDataSourceId === dataSourceId,
        )
        if (!existingOverride) return

        // Update the specific override with the form event data
        const updatedOverride = { ...existingOverride, [e.target.name]: e.target.value }

        updatedScenario.scenarioDataSourceOverrides = updatedScenario.scenarioDataSourceOverrides.map((override) => {
            return override.profileDataSourceId === dataSourceId ? updatedOverride : override
        })

        // Update the state (React should detect the change if scenario itself is a state managed object)
        setScenario(updatedScenario)
        setScenarioModified(true)
    }

    const updateScenario = (e: FormControlChangeEvent) => {
        setScenario(updateObjectWithFormEvent(scenario, e))
        setScenarioModified(true)
    }

    // Update the upload progress
    const handleUploadProgress = (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
            const percentCompleted = progressEvent.loaded / progressEvent.total
            setUploadProgress(percentCompleted)
        }
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        if (!scenario) {
            throw Error('Scenario is not defined')
        }
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        // validate
        setValidatedForm(true)

        const form = event.target as HTMLFormElement
        if (form.checkValidity() === false) {
            return
        }

        if (areTagsInValid()) {
            return
        }

        // create a new scenario, or open confirm dialog for edit
        if (operationMode !== OperationModes.Edit) {
            await performOperation(operationMode)
        } else {
            setShowConfirm(true)
        }
    }

    const handleModifyConfirmResult = async (dialogResult: DialogResultEnum) => {
        if (!scenario) {
            return
        }
        if (dialogResult === DialogResultEnum.Completed) {
            await performOperation(OperationModes.Edit)
        }

        setShowConfirm(false)
    }

    const performOperation = async (mode: OperationModes) => {
        if (!scenario) {
            return
        }
        try {
            await dispatch(saveScenarioAndUpdatedTemplate(scenario, mode, handleUploadProgress))
            if (isDataSourceUpdating) {
                // upload will result in import - fwd to File Processing
                navigate('/fileprocessing')
            } else {
                // just updating scenario details - fwd to schedule list
                navigate(`/scenario/${scenario.id}/schedules`)
            }
        } catch (error: any) {
            toast.error(error.message)
            return
        }
        toast.success(`Scenario has been ${formatOperation(mode)}`)
    }

    const title = formatTitle(operationMode, 'Scenario')
    const footer = (
        <FormFooter
            contentLeft={
                validatedForm &&
                isDataSourceUpdating && <ProgressBar textDescription="" progressValue={uploadProgress} />
            }
            disabledSave={!scenarioModified && !templateChanged}
            onCancel={() => {
                if (operationMode === OperationModes.Edit) {
                    navigate(`/scenario/${scenario?.id}/schedules`)
                    return
                }

                navigate('/scenarios')
            }}
        />
    )

    if (!scenario) {
        return <></>
    }

    return (
        <>
            <FormPage
                headingContent={title}
                footerContent={footer}
                validatedForm={validatedForm}
                onSubmit={submitHandler}
            >
                <FormSection title="Details">
                    <InputTextFormRow
                        labelText="Name"
                        fieldId="name"
                        subText="A unique name for this scenario"
                        value={scenario.name}
                        onChange={updateScenario}
                        required
                        invalidText="Enter a unique name"
                    />
                    <InputTextAreaFormRow
                        labelText="Description"
                        fieldId="description"
                        subText="A description for this scenario"
                        value={scenario.description}
                        onChange={updateScenario}
                    />
                </FormSection>

                <FormSection title="Tags">
                    <TagsTable
                        tags={scenario.scenarioTags}
                        allTagNames={scenarioTagDefinitions}
                        showValidationErrors={validatedForm}
                        updateTags={(tags: TagKeyValue[]) => {
                            setScenarioModified(true)
                            setScenario((previousScenario: Scenario | undefined) => {
                                if (!previousScenario) return previousScenario
                                return {
                                    ...previousScenario,
                                    scenarioTags: tags,
                                }
                            })
                        }}
                    />
                </FormSection>
                <FormSection title="Template" padding={0}>
                    <TemplateExpansionPanel onChange={updateScenario}>
                        <ProfilesSelectorPanel />
                    </TemplateExpansionPanel>
                </FormSection>
                <DataSourcesSelectorPanel
                    scenario={scenario}
                    validated={validatedForm}
                    onFileChange={onFileChange}
                    onOverrideChange={onOverrideChange}
                />
            </FormPage>
            {showConfirm && <ScenarioSaveConfirmationDialog closeCallback={handleModifyConfirmResult} />}
        </>
    )
}

export default ScenarioDetailPage
