import globals from 'services/global/globals'
import { handleApiError } from 'services/utilities/toastrUtils'
import ConfirmationDialog from 'views/Common/GenericDialogs/ConfirmationDialog'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'

const getDeleteMessage = (numRows: number) =>
    `Are you sure you want to delete the ${numRows} selected schedule${numRows > 1 ? 's' : ''}?`

const DeleteScheduleConfirmationDialog = (props: {
    scheduleIds: number[]
    onCloseDialog: (result: DialogResultEnum) => void
}) => {
    const api = globals.getApi().getScheduleApi()
    return (
        <ConfirmationDialog
            headerText="Delete Schedule"
            closeCallback={() => props.onCloseDialog(DialogResultEnum.Cancelled)}
            confirmedCallback={() => {
                const doDelete = async () => {
                    try {
                        await api.deleteSchedules(props.scheduleIds)
                        props.onCloseDialog(DialogResultEnum.Completed)
                    } catch (err: any) {
                        handleApiError(err)
                    }
                }
                doDelete()
            }}
        >
            {getDeleteMessage(props.scheduleIds.length)}
        </ConfirmationDialog>
    )
}

export default DeleteScheduleConfirmationDialog
