import { Alert } from 'react-bootstrap'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import ScheduleDetail from 'types/Schedule'
import ScheduleEvent from 'types/ScheduleEvent'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import classes from './EventOverlapsBanner.module.css'

const EventOverlapsBanner = ({
    elementRef,
    schedule,
    editOverlapClicked,
}: {
    elementRef: any
    schedule: ScheduleDetail
    editOverlapClicked: (event1: ScheduleEvent, event2: ScheduleEvent) => void
}) => {
    const overlaps = schedule.errors
    if (overlaps.length > 0) {
        const scheduleTimeMode = schedule.viewSettings.timeMode
        const events = schedule.events

        // generate list of overlaps
        const overlapListItems = overlaps.map((eventOverlapPair, index) => {
            const event1 = events.find((x) => x.uuid === eventOverlapPair.overlapEvent1Uuid)!
            const event1StartFormatted = new Date(event1.getStartMsForDisplay(scheduleTimeMode))
            const event2 = events.find((x) => x.uuid === eventOverlapPair.overlapEvent2Uuid)!
            const event2StartFormatted = new Date(event2.getStartMsForDisplay(scheduleTimeMode))
            return (
                <li key={index}>
                    <TransparentButton onClick={() => editOverlapClicked(event1, event2)}>
                        <strong>Overlap:</strong> {event1.label} at{' '}
                        {dateTimeFormatting.formatDateTimeShort(event1StartFormatted.getTime())} overlaps with{' '}
                        {event2.label} at {dateTimeFormatting.formatDateTimeShort(event2StartFormatted.getTime())}
                    </TransparentButton>
                </li>
            )
        })

        return (
            <Alert variant="danger" ref={elementRef} className={classes.alertbox}>
                <p>The following problems need to be fixed in your schedule:</p>
                <ul>{overlapListItems}</ul>
            </Alert>
        )
    }

    return null
}

export default EventOverlapsBanner
