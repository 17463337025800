import logo from 'assets/SFLogoWhiteTextNoTag.svg'
import { useSettingValues } from 'hooks/useSettingValue'
import useUser from 'hooks/useUser'
import { useEffect, useState } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import globals from 'services/global/globals'
import { RootState } from 'store/store'
import { SettingConsts } from 'types/SystemSetting'
import SimpleDialog from 'views/Common/GenericDialogs/SimpleDialog'
import classes from './Navbar.module.css'

const SFNavbar = () => {
    const api = globals.getApi()
    const [appVersion, setAppVersion] = useState('')
    const user = useUser()
    useEffect(() => {
        const loadData = async () => {
            setAppVersion(await api.getAppVersion())
        }
        loadData()
    }, [api])

    const settingValues = useSettingValues([
        SettingConsts.general.account_Announcement,
        SettingConsts.general.features_Reporting,
        SettingConsts.general.features_Insights,
        SettingConsts.general.features_Data_Imports,
        SettingConsts.general.features_Data_Exports,
        SettingConsts.general.help_Support_Portal,
        SettingConsts.general.help_Training_Academy,
        SettingConsts.general.help_Training_Academy_URL,
    ])
    const loginAnnouncement = settingValues[0]
    const enableFeatureReports = settingValues[1] === 'true'
    const enableFeatureInsights = settingValues[2] === 'true'
    const enableFeatureImports = settingValues[3] === 'true'
    const enableFeatureExports = settingValues[4] === 'true'
    const enableHelpSupportPortal = settingValues[5] === 'true'
    const enableHelpTrainingAcademy = settingValues[6] === 'true'
    const trainingAcademyUrl = settingValues[7]
    const isPrintPreview = useSelector<RootState, boolean>((x) => x.app.isPrintPreview)

    const location = useLocation()

    const isScheduleActive = location.pathname.includes('/scenario/') || location.pathname.includes('/schedule/')
    const isDesktopAdmin = user?.name === 'DesktopAdmin'
    return (
        <>
            {!isPrintPreview && (
                <Navbar id="mainNavigation" className={`px-3 navbar-dark ${classes.navbarOverride}`} expand="sm">
                    <Navbar.Brand href="/scenarios/" className={classes.navbarBrandOverride}>
                        <div className={classes.sfLogo} style={{ backgroundImage: 'url(' + logo + ')' }} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <NavLink className="nav-to-scenarios nav-link" to="/scenarios" end>
                                Scenarios
                            </NavLink>
                            <NavLink
                                className={
                                    isScheduleActive ? 'nav-to-schedules nav-link active' : 'nav-to-schedules nav-link'
                                }
                                to="/schedules/"
                            >
                                Schedules
                            </NavLink>
                            {enableFeatureReports && (
                                <NavLink className="nav-to-reports nav-link" to="/reports/">
                                    Reports
                                </NavLink>
                            )}
                            {enableFeatureInsights && (
                                <NavLink className="nav-to-insights nav-link" to="/insights/">
                                    Insights
                                </NavLink>
                            )}
                            {(enableFeatureImports || enableFeatureExports) && (
                                <NavLink className="nav-to-imports nav-link" to="/fileprocessing/">
                                    File Processing
                                </NavLink>
                            )}
                            <NavLink className="nav-to-users nav-link" to="/settings/templates">
                                Settings
                            </NavLink>
                        </Nav>
                        <Nav>
                            {!isDesktopAdmin && (
                                <NavLink className="nav-link" to="/logout">
                                    Sign Out
                                </NavLink>
                            )}
                            <NavDropdown title="Help" id="basic-nav-dropdown" align="end">
                                <a
                                    id="lnkUserGuide"
                                    className="dropdown-item static-url"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="/docs/user-manual/index.htm"
                                >
                                    User Manual
                                </a>
                                {enableHelpSupportPortal && (
                                    <a
                                        id="lnkSupportPortal"
                                        className="dropdown-item static-url"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://ibrinc.atlassian.net/servicedesk/customer/portal/3"
                                    >
                                        Support Portal
                                    </a>
                                )}
                                {enableHelpTrainingAcademy && (
                                    <a
                                        id="lnkTrainingAcademy"
                                        className="dropdown-item static-url"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={trainingAcademyUrl || 'https://ibr.talentlms.com/'}
                                    >
                                        Training Academy
                                    </a>
                                )}
                                {!isDesktopAdmin && (
                                    <a className="dropdown-item static-url" href="/setpasswordrequest">
                                        Reset Password
                                    </a>
                                )}
                                <NavDropdown.Divider />
                                <div id="appVersion" className="text-muted small ps-4">
                                    Version: {appVersion}
                                    {!isDesktopAdmin && (
                                        <>
                                            <br />
                                            Account: {user?.accountName}
                                            <br />
                                            User: {user?.name}
                                        </>
                                    )}
                                </div>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            )}
            <>
                {loginAnnouncement && (
                    <SimpleDialog headerText="Attention" closeCallback={() => {}}>
                        {/* loginAnnouncement is a value controlled by safte-fast support, content can be trusted */}
                        {/* eslint-disable */}
                        <div dangerouslySetInnerHTML={{ __html: loginAnnouncement }} />
                        {/* eslint-enable */}
                    </SimpleDialog>
                )}
            </>
        </>
    )
}

export default SFNavbar
