import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools'
import {
    GridCellProps,
    GridColumnMenuProps,
    GridColumnMenuWrapper,
    GridHeaderCellProps,
    GRID_COL_INDEX_ATTRIBUTE,
} from '@progress/kendo-react-grid'
import useUser from 'hooks/useUser'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { Permission, User } from 'types/interfaces'
import { ScenarioDetail } from 'types/Scenario'
import ScheduleDetail from 'types/Schedule'
import InfoIcon from 'views/Common/Widget/InfoIcon'
import getTipContent from 'views/Schedules/ScheduleDetails/Components/ScheduleWarningContent'
import TransparentButton from '../Buttons/TransparentButton'
import { ColumnMenuWithFilter } from './CustomColumnMenu'
import classes from './FormattedNameCell.module.css'

const createContextLabel = (label: string) => <div style={{ fontSize: '0.8em', color: '#777' }}>{label}</div>
const createFormattedMetricHeaderCell = (
    props: GridHeaderCellProps,
    label: string,
    context?: 'Crewing' | 'Critical',
    noFilter?: boolean,
) => {
    return (
        <span className="k-cell-inner">
            <span onClick={props.onClick} role="button" tabIndex={0} onKeyDown={() => {}} className="k-link">
                <span className="k-column-title">
                    <div style={{ lineHeight: '12px' }}>{label}</div>
                    {context && createContextLabel(`(${context})`)}
                </span>
                {props.children}
                <span aria-label="Sortable" />
            </span>
            {noFilter !== true && (
                <GridColumnMenuWrapper
                    {...props.columnMenuWrapperProps}
                    columnMenu={(colMenuProps: GridColumnMenuProps) => ColumnMenuWithFilter(colMenuProps)}
                />
            )}
        </span>
    )
}

const FormattedNameCell = (props: GridCellProps, content: JSX.Element) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id)
    return (
        <td
            style={props.style}
            colSpan={props.colSpan}
            role="gridcell"
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            className={`${classes.cell} ${props.className}`}
            {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
            {...navigationAttributes}
        >
            {content}
        </td>
    )
}

const getLink = (url: string, text: string) => (
    <Link className={`grid-button linkStyle ${classes.link}`} to={url}>
        {text}
    </Link>
)

const getErrorIcon = (tooltip: string) => (
    <InfoIcon
        icon="bi-exclamation-circle-fill"
        tooltip={tooltip}
        className="grid-button "
        style={{ cursor: 'pointer', marginLeft: '5px', color: '#dc3545', fontSize: '1.2em' }}
    />
)

const getClickableSharingIcon = (
    permission: Permission,
    itemType: string,
    clicked: (e: React.MouseEvent<HTMLElement>) => void,
    scenario?: ScenarioDetail,
    user?: User,
) => {
    let tooltip = ''
    let isClickable = false
    if (permission.sharedWithMePermission) {
        let isAdministrator = false
        if (user != null) {
            isAdministrator = user!.isAdministrator
        }
        isClickable = permission.amOwnerOfSharedItem || isAdministrator
        tooltip = `This ${itemType} has been shared with you with permission to ${permission.sharedWithMePermission}`
    } else if (permission.amOwnerOfSharedItem === true) {
        tooltip = `You have shared this ${itemType}`
        isClickable = true
    } else if (permission.isSharedThroughChildItem === true) {
        isClickable = scenario?.createdById === user!.id
        tooltip = `This ${itemType} has shared schedules`
    }
    return (
        <TransparentButton
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                if (isClickable) clicked(e)
            }}
            tooltip={tooltip}
        >
            <i
                className="grid-button bi-people-fill text-secondary"
                style={{ cursor: 'pointer', marginLeft: '8px', fontSize: '1.4em' }}
            />
        </TransparentButton>
    )
}
const getDraftIcon = () => (
    <InfoIcon
        icon="bi-pencil-fill"
        tooltip="This schedule has unsaved changes in progress.  The results shown in the table are from the saved schedule."
        className="grid-button"
        variant="text-warning"
        style={{ marginLeft: '8px', fontSize: '1.3em' }}
    />
)

const getWarningIcon = (schedule: ScheduleDetail) => {
    const tipContent = getTipContent(schedule)
    return (
        <span data-tip={ReactDOMServer.renderToString(tipContent)}>
            <InfoIcon
                icon="bi-exclamation-circle-fill"
                className="grid-button"
                variant="text-warning"
                style={{ marginLeft: '8px', fontSize: '1.3em' }}
            />
            <ReactTooltip html effect="solid" />
        </span>
    )
}

const isSharedItem = (permission: Permission) => {
    return (
        permission.sharedWithMePermission ||
        permission.amOwnerOfSharedItem === true ||
        permission.isSharedThroughChildItem === true
    )
}

/**
 * Scenario Name cell on Scenarios Listing page
 */
const FormattedScenarioNameCell = (props: GridCellProps, shareIconClicked: (scenarioId: number) => void) => {
    const user = useUser()
    const scenario = props.dataItem as ScenarioDetail
    const url = `/scenario/${scenario.id}/schedules`
    const permission = scenario.permission
    const clickedThisScenarioShareIcon = () => shareIconClicked(scenario.id)
    const cellContent = (
        <>
            {getLink(url, scenario.name)}
            {isSharedItem(permission) &&
                getClickableSharingIcon(permission, 'scenario', clickedThisScenarioShareIcon, scenario, user)}
            {scenario.hasFailedSchedules && getErrorIcon('This scenario has schedules with errors.')}
        </>
    )
    return FormattedNameCell(props, cellContent)
}

/**
 * Schedule Name (aka ID) cell on Schedules Listing page
 */
const FormattedScheduleNameCell = (
    props: GridCellProps,
    scheduleErrorClicked: () => void,
    shareIconClicked: (scheduleId: number) => void,
) => {
    const schedule = props.dataItem as ScheduleDetail
    const url = `/schedule/${schedule.id}/view`

    const permission = schedule.permission
    const clickableErrorIcon = (
        <TransparentButton onClick={() => scheduleErrorClicked()}>
            {getErrorIcon('This schedule contains errors.  Click for details.')}
        </TransparentButton>
    )

    const clickedThisScheduleShareIcon = () => shareIconClicked(schedule.id)

    const cellContent = (
        <div>
            {getLink(url, schedule.name)}
            {isSharedItem(permission) && getClickableSharingIcon(permission, 'schedule', clickedThisScheduleShareIcon)}
            {schedule.draftScheduleId && getDraftIcon()}
            {schedule.isFailedSchedule && clickableErrorIcon}
            {schedule.allNonCrewing && getWarningIcon(schedule)}
        </div>
    )
    return FormattedNameCell(props, cellContent)
}

export {
    createFormattedMetricHeaderCell,
    FormattedNameCell,
    FormattedScenarioNameCell,
    FormattedScheduleNameCell,
    getLink,
    getClickableSharingIcon,
    isSharedItem,
}
