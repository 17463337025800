import Axios from 'services/axios/axios-sfc'
import { ScheduleDetailsViewMode, SleepQualityEnum, TimeModeEnum, ViewSettings } from 'types/interfaces'
import { ResponseMessage } from 'types/ResponseMessage'
import { parseScenarioParameters, ScenarioParameters } from 'types/Scenario'
import ScheduleDetail, {
    CreateEventsSchedule,
    CreateUpdateShiftsSchedule,
    parseSchedule,
    Schedule,
    ScheduleWithEvents,
} from 'types/Schedule'
import ScheduleEvent, { ScheduleEventRecord, TagKeyValue } from 'types/ScheduleEvent'
import { UpdateDutyRequest } from 'types/UpdateDutyRequest'
import { UpdateDutyTimeRequest } from 'types/UpdateDutyTimeRequest'
import SFApiBase from './sfApiBase'

class SFScheduleApi extends SFApiBase {
    constructor(private axios: Axios) {
        super()
    }

    deleteEvent = async (
        schedule: ScheduleDetail,
        scheduleEvents: ScheduleEvent[],
    ): Promise<[ScheduleDetail, ResponseMessage?]> => {
        const data = (
            await this.axios.post(
                this.baseUrl + '/Api/Schedules/DeleteEvent',
                {
                    scheduleId: schedule.id,
                    eventUuids: scheduleEvents.map((x) => x.uuid),
                },
                this.getConfig(),
            )
        ).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    updateScheduleWithEvents = async (schedule: ScheduleWithEvents): Promise<[ScheduleDetail, ResponseMessage?]> => {
        const data = (
            await this.axios.post(
                this.baseUrl + '/Api/Schedules/UpdateAndReanalyzeSchedule',
                {
                    schedule: convertScheduleForServer(schedule),
                    hasNewDuty: false,
                },
                this.getConfig(),
            )
        ).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    getScheduleParameters = async (scheduleId: number): Promise<ScenarioParameters> => {
        const responseData = (
            await this.axios.get(this.baseUrl + `/Api/Schedules/${scheduleId}/Parameters`, this.getConfig())
        ).data
        return parseScenarioParameters(responseData)
    }

    updateScheduleParameters = async (
        scheduleId: number,
        parameters: ScenarioParameters,
    ): Promise<[ScheduleDetail, ResponseMessage?]> => {
        const responseData = (
            await this.axios.put(this.baseUrl + `/Api/Schedules/${scheduleId}/Parameters`, parameters, this.getConfig())
        ).data as any
        return [parseSchedule(responseData.schedule), responseData.message]
    }

    resetEditedSleep = async (scheduleId: number): Promise<[ScheduleDetail, ResponseMessage?]> => {
        const responseData = (
            await this.axios.post(
                this.baseUrl + `/Api/Schedules/${scheduleId}/ResetExplicitSleep`,
                {},
                this.getConfig(),
            )
        ).data as any
        return [parseSchedule(responseData.schedule), responseData.message]
    }

    addEvent = async (scheduleEvent: ScheduleEventRecord): Promise<[ScheduleDetail, ResponseMessage?]> => {
        // server doesn't accept "Duty" as an event type, so we need to switch it here before sending.
        const isDuty = scheduleEvent.type === 'Duty'
        if (isDuty) {
            scheduleEvent.type = 'Work'
        }

        const autoEventsState = isDuty ? 'NotCreated' : 'Deleted'
        const postData = {
            dutyItemState: [
                {
                    state: autoEventsState,
                    workType: 'Preparation',
                },
                {
                    state: autoEventsState,
                    workType: 'CommuteToWork',
                },
                {
                    state: autoEventsState,
                    workType: 'Brief',
                },
                {
                    state: autoEventsState,
                    workType: 'Debrief',
                },
                {
                    state: autoEventsState,
                    workType: 'CommuteFromWork',
                },
                {
                    state: autoEventsState,
                    workType: 'Unwind',
                },
            ],
            event: convertEventForServer(scheduleEvent),
        }
        const responseData = (
            await this.axios.post(this.baseUrl + '/Api/Schedules/CreateEvent', postData, this.getConfig())
        ).data as any
        const parsedSchedule = parseSchedule(responseData.schedule)
        return [parsedSchedule, responseData.message]
    }

    editEvent = async (scheduleEvent: ScheduleEventRecord): Promise<[ScheduleDetail, ResponseMessage?]> => {
        // need to change enum values to strings
        const postData = convertEventForServer(scheduleEvent)
        const responseData = (
            await this.axios.post(this.baseUrl + '/Api/Schedules/EditEvent/', postData, this.getConfig())
        ).data as any
        const parsedSchedule = parseSchedule(responseData.schedule)
        return [parsedSchedule, responseData.message]
    }

    editDuty = async (request: UpdateDutyRequest): Promise<[ScheduleDetail, ResponseMessage?]> => {
        const responseData = (
            await this.axios.post(this.baseUrl + '/Api/Schedules/EditDuty', request, this.getConfig())
        ).data as any
        return [parseSchedule(responseData.schedule), responseData.message]
    }

    setDutyTime = async (request: UpdateDutyTimeRequest): Promise<[ScheduleDetail, ResponseMessage?]> => {
        const responseData = (
            await this.axios.post(this.baseUrl + '/Api/Schedules/EditDutyDates', request, this.getConfig())
        ).data as any
        return [parseSchedule(responseData.schedule), responseData.message]
    }

    deleteSchedules = async (scheduleIds: number[]): Promise<void> => {
        await this.axios.delete(this.baseUrl + '/Api/Schedules/Details/', scheduleIds, this.getConfig())
    }

    duplicateSchedule = async (
        scheduleId: number,
        isSaveAs: boolean,
        name: string,
        newScenarioId: number,
        newScenarioName: string,
    ): Promise<number> => {
        const response = (
            await this.axios.post(
                this.baseUrl + '/Api/Schedules/DuplicateSchedule/',
                {
                    scheduleId,
                    isSaveAs,
                    name,
                    newScenarioName,
                },
                this.getConfig(),
            )
        ).data
        return response.id as number
    }

    saveDraftSchedule = async (scheduleId: number, lastModified: Date): Promise<[ScheduleDetail, ResponseMessage?]> => {
        const data = (
            await this.axios.post(
                this.baseUrl + '/Api/Schedules/SaveDraft/',
                {
                    draftScheduleId: scheduleId,
                    scheduleModified: lastModified,
                },
                this.getConfig(),
            )
        ).data as any

        return [parseSchedule(data.schedule), data.message]
    }

    discardDraftSchedule = async (scheduleId: number, lastModified: Date): Promise<void> => {
        await this.axios.post(
            this.baseUrl + '/Api/Schedules/DiscardDraft/',
            {
                draftScheduleId: scheduleId,
                scheduleModified: lastModified,
            },
            this.getConfig(),
        )
    }

    getScheduleWithDetails = async (scheduleId: number): Promise<ScheduleDetail> => {
        const data = (await this.axios.get(this.baseUrl + `/Api/Schedules/${scheduleId}`, this.getConfig())).data as any
        return parseSchedule(data.schedule) as ScheduleDetail
    }

    // temporary name, too close to the existing getScheduleWithDetails
    getScheduleDetails = async (scheduleId: number): Promise<Schedule> => {
        const data = (await this.axios.get(this.baseUrl + `/Api/Schedules/Details/${scheduleId}`, this.getConfig()))
            .data as Schedule
        return data
    }

    createSchedule = async (schedule: Schedule): Promise<Schedule> => {
        const data = (await this.axios.post(this.baseUrl + '/Api/Schedules/Details', schedule, this.getConfig()))
            .data as Schedule
        return data
    }

    updateSchedule = async (schedule: Schedule): Promise<Schedule> => {
        const data = (await this.axios.put(this.baseUrl + '/Api/Schedules/Details', schedule, this.getConfig()))
            .data as Schedule
        return data
    }

    // todo: consolidate these methods
    getDefaultSchedule = async (scenarioId: number): Promise<Schedule> => {
        return (
            await this.axios.get(this.baseUrl + `/Api/Schedules/Details/CreateEntity/${scenarioId}`, this.getConfig())
        ).data as Schedule
    }
    copySchedule = async (scheduleId: number, scenarioId: number): Promise<Schedule> => {
        const scheduleDetail = (
            await this.axios.get(
                this.baseUrl + `/Api/Schedules/Details/CreateEntity/${scenarioId}/${scheduleId}`,
                this.getConfig(),
            )
        ).data as Schedule
        scheduleDetail.id = 0
        return scheduleDetail
    }

    saveScheduleSettingsForAnalysis = async (
        scheduleId: number,
        scheduleName: string,
        scheduleBase: string,
        scheduleTags: TagKeyValue[],
    ): Promise<[ScheduleDetail, ResponseMessage?]> => {
        const data = (
            await this.axios.put(
                this.baseUrl + '/Api/Schedules/UpdateScheduleSettingsForAnalysis',
                {
                    scheduleId,
                    scheduleName,
                    scheduleBase,
                    scheduleTags,
                },
                this.getConfig(),
            )
        ).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    saveScheduleViewSettings = async (scheduleId: number, scheduleViewSettings: ViewSettings) => {
        const viewSettings = {
            ...scheduleViewSettings,
            timeMode: TimeModeEnum[scheduleViewSettings.timeMode].toString(),
            viewMode: ScheduleDetailsViewMode[scheduleViewSettings.viewMode].toString(),
        }
        await this.axios.put(
            this.baseUrl + '/Api/Schedules/UpdateScheduleViewSettings',
            {
                scheduleId,
                scheduleViewSettings: viewSettings,
            },
            this.getConfig(),
        )
    }

    updateLastViewed = async (id: number): Promise<void> => {
        await this.axios.put(
            this.baseUrl + '/Api/Schedules/UpdateLastViewed/',
            {
                id,
            },
            this.getConfig(),
        )
    }

    createNewEventsSchedule = async (
        createSchedule: CreateEventsSchedule,
    ): Promise<[ScheduleDetail, ResponseMessage?]> => {
        const data = (
            await this.axios.post(
                this.baseUrl + '/Api/Schedules/CreateNewEventsSchedule',
                createSchedule,
                this.getConfig(),
            )
        ).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    createNewShiftsSchedule = async (
        createSchedule: CreateUpdateShiftsSchedule,
    ): Promise<[ScheduleDetail, ResponseMessage?]> => {
        const data = (
            await this.axios.post(
                this.baseUrl + '/Api/Shifts/CreateOrUpdateShiftsSchedule',
                createSchedule,
                this.getConfig(),
            )
        ).data as any
        return [parseSchedule(data.schedule), data.message]
    }
}

const convertEventForServer = (eventRecord: ScheduleEventRecord): any => {
    const getSleepQualityStringOrNull = (value: SleepQualityEnum | null): string | null => {
        if (!value) {
            return null
        }

        return SleepQualityEnum[value].toString()
    }

    return {
        ...eventRecord,
        quality: getSleepQualityStringOrNull(eventRecord.quality),
        plannedWorkSleepQuality: getSleepQualityStringOrNull(eventRecord.plannedWorkSleepQuality),
    }
}

const convertScheduleForServer = (schedule: ScheduleWithEvents): any => {
    // this gets rid of some content from the schedule object
    // filter out autosleep, duty rollupts, etc.
    const scheduleForServer = { ...schedule }
    scheduleForServer.events = [...scheduleForServer.events]
        .filter((x) => !x.isGeneratedEvent())
        .map(convertEventForServer)
    return scheduleForServer
}

export default SFScheduleApi
