import { useParams } from 'react-router-dom'
import OperationModes from 'types/OperationModes'

export interface DetailPageParams {
    id: string
    operationMode: OperationModes
}
const useDetailPageParams = (): DetailPageParams => {
    const { id, operation } = useParams<{ id?: string; operation?: string }>()
    if (!id) {
        throw new Error('id is required in url parameters for useDetailPageParams')
    }
    let operationMode: OperationModes = OperationModes.Add
    if (id === undefined || id === 'new') {
        operationMode = OperationModes.Add
    } else if (operation?.toLowerCase() === 'copy') {
        operationMode = OperationModes.Copy
    } else if ((id && !operation) || operation?.toLowerCase() === 'edit') {
        operationMode = OperationModes.Edit
    }

    return { id, operationMode }
}

export default useDetailPageParams
