import { RadioButton, RadioButtonChangeEvent } from '@progress/kendo-react-inputs'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import OperationModes from 'types/OperationModes'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'

interface Props {
    closeCallback: (dialogResult: DialogResultEnum, mode?: OperationModes) => void
}
const TemplateSaveConfirmationDialog = ({ closeCallback }: Props) => {
    const [selected, setSelected] = useState<OperationModes>(OperationModes.Add)
    const handleChange = (e: RadioButtonChangeEvent) => {
        setSelected(e.value)
    }
    return (
        <ModalWrapper closeCallback={() => closeCallback(DialogResultEnum.Cancelled)} size="lg">
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Updating Template </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Updating this template will affect existing projects</p>
                    <>
                        <div className="row k-mb-4">
                            <div className="col col-4">
                                <RadioButton
                                    name="options"
                                    label="Save Template"
                                    value="save"
                                    checked={selected === OperationModes.Edit}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col col-8">
                                <div className="k-label">This may result in data source refresh and/or reanalysis.</div>
                            </div>
                        </div>
                        <div className="row k-mb-4">
                            <div className="col col-4">
                                <RadioButton
                                    name="options"
                                    label="Save as a new template"
                                    value="new"
                                    checked={selected !== OperationModes.Edit}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col col-8">
                                <div className="k-label">
                                    Create a new template instead of updating this template. Related projects will not
                                    be affected.
                                </div>
                            </div>
                        </div>
                    </>
                </Modal.Body>

                <Modal.Footer>
                    <ButtonCustom
                        isLarge
                        type="submit"
                        variant="primary"
                        onClick={() => closeCallback(DialogResultEnum.Completed, selected)}
                    >
                        OK
                    </ButtonCustom>
                    <ButtonCustom isLarge variant="secondary" onClick={() => closeCallback(DialogResultEnum.Cancelled)}>
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </>
        </ModalWrapper>
    )
}

export default TemplateSaveConfirmationDialog
