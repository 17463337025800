import { GridCellProps, GridFilterCellProps, GridHeaderCellProps } from '@progress/kendo-react-grid'
import { getPaletteWithParameters } from 'hooks/usePalettes'
import { CSSProperties } from 'react'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import { getHeaderCellClass } from 'services/utilities/kendoGridColumnsHelpers'
import { getFilterFields } from 'services/utilities/kendoGridUtils'
import { GridLayout, KendoGridColumn, KendoGridColumnCustomAttributes } from 'types/GridLayout'
import { ColorPalettes, TimeModeEnum } from 'types/interfaces'
import ScheduleDetail from 'types/Schedule'
import ScheduleEvent from 'types/ScheduleEvent'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import { ColumnMenuWithFilter } from 'views/Common/Kendo/CustomColumnMenu'
import DropdownFilterCell from 'views/Common/Kendo/DropdownFilterCell'
import EventLabelCell from 'views/Common/Kendo/EventLabelCell'
import { CrewingCell } from 'views/Common/Kendo/FormattedCell'
import { FormattedEventMetricCell } from 'views/Common/Kendo/FormattedMetricCell'
import { createFormattedMetricHeaderCell } from 'views/Common/Kendo/FormattedNameCell'

export type ClickEditTimeParams = {
    startTime: Date
    startTimeLocalized: Date
    endTimeLocalized: Date
    endTime: Date
    timePosition: 'start' | 'end'
    dutyUuid: string
}

export const CrewingFilterTrue = 'Crewing'
export const CrewingFilterFalse = 'Non-Crewing'
export const CriticalFilterTrue = 'Critical'
export const CriticalFilterFalse = 'Non-Critical'

const invalidCellStyling: CSSProperties = {
    border: '2px solid red',
}

const eventHasOverlap = (schedule: ScheduleDetail, scheduleEvent: ScheduleEvent, timePosition: 'start' | 'end') => {
    if (timePosition === 'start') {
        return schedule.errors.filter((x) => x.overlapEvent2Uuid === scheduleEvent.uuid!).length > 0
    }
    return schedule.errors.filter((x) => x.overlapEvent1Uuid === scheduleEvent.uuid!).length > 0
}

const getClassCrewing = (scheduleEvent: ScheduleEvent): any => {
    return `${scheduleEvent.crewing ? 'labelCrewing' : ''}`
}

const getDateCellContent = (
    scheduleEvent: ScheduleEvent,
    timeProperty: string,
    readOnly: boolean,
    clickEditTime: () => void,
) => {
    const scheduleEventAny = scheduleEvent as any
    const formattedTime = dateTimeFormatting.formatDateTimeShort(scheduleEventAny[timeProperty].getTime())
    if (scheduleEvent.isDutyRollup() && !readOnly) {
        return <TransparentButton onClick={clickEditTime}>{formattedTime}</TransparentButton>
    }
    return <>{formattedTime}</>
}

const getColumns = (
    schedule: ScheduleDetail,
    allScheduleEvents: ScheduleEvent[],
    palettes: ColorPalettes,
    collapsedDutyIds: string[],
    eventButtonClicked: (scheduleEvent: ScheduleEvent) => void,
    clickDutyToggle: (operation: 'expand' | 'collapse', dutyId: string) => void,
    clickEditTime: (args: ClickEditTimeParams) => void,
    gridLayout: GridLayout | undefined,
    readOnly: boolean,
): KendoGridColumn[] => {
    const getFormattedMetricCell = (props: GridCellProps): JSX.Element => {
        const scenarioPalette = getPaletteWithParameters(schedule.scenarioParameters, palettes)
        return FormattedEventMetricCell(schedule, props, scenarioPalette)
    }

    const clickEditTimeHandler = (scheduleEvent: ScheduleEvent, timePosition: 'start' | 'end') => {
        const scheduleEventAny = scheduleEvent as any
        clickEditTime({
            startTime: scheduleEvent.start,
            startTimeLocalized: scheduleEventAny.start_formatted,
            endTime: new Date(scheduleEvent.getEndMs()),
            endTimeLocalized: scheduleEventAny.end_formatted,
            timePosition,
            dutyUuid: scheduleEvent.dutyUuid!,
        })
    }

    if (!gridLayout) {
        return []
    }

    const enableFiltering = gridLayout?.configurationJson.filtersEnabled || false
    const filteredFields = getFilterFields(gridLayout.configurationJson.dataState?.filter?.filters || [])

    const columns =
        gridLayout.configurationJson.columns.map((column) => {
            const customizedColumn = { ...column }
            const {
                customizations: {
                    startOrEndDate,
                    hasColumnMenuWithFilter,
                    hasDropdownFilterCell,
                    hasCustomHeaderCell,
                    hasCustomTimeModeTitle,
                    customCellType,
                    dataContext,
                } = {} as KendoGridColumnCustomAttributes,
            } = column

            customizedColumn.headerClassName = getHeaderCellClass(column.field!, filteredFields)

            switch (customCellType) {
                case 'eventName':
                    customizedColumn.cell = (props: GridCellProps) => {
                        const scheduleEvent = props.dataItem as ScheduleEvent
                        const isCollapsed =
                            scheduleEvent.isDutyRollup() && collapsedDutyIds.includes(scheduleEvent.dutyUuid!)
                        return EventLabelCell(
                            props,
                            isCollapsed,
                            allScheduleEvents,
                            eventButtonClicked,
                            clickDutyToggle,
                        )
                    }
                    break
                case 'eventDate':
                    customizedColumn.cell = (props: GridCellProps) => {
                        const scheduleEvent = props.dataItem as ScheduleEvent
                        if (!startOrEndDate) {
                            throw Error('startOrEndDate must be set for eventDate cell type')
                        }
                        const cellStyling = eventHasOverlap(schedule, scheduleEvent, startOrEndDate)
                            ? invalidCellStyling
                            : {}
                        return (
                            <td style={cellStyling} className={getClassCrewing(scheduleEvent)}>
                                {getDateCellContent(scheduleEvent, column.field!, readOnly, () =>
                                    clickEditTimeHandler(scheduleEvent, startOrEndDate!),
                                )}
                            </td>
                        )
                    }
                    break
                case 'eventCrewingCell':
                    customizedColumn.cell = CrewingCell
                    break
                case 'eventFormattedMetric':
                    customizedColumn.cell = getFormattedMetricCell
                    break
                default:
                    break
            }

            if (hasCustomHeaderCell) {
                customizedColumn.headerCell = (props: GridHeaderCellProps) =>
                    createFormattedMetricHeaderCell(props, customizedColumn.title!, dataContext, !enableFiltering)
            }

            if (hasCustomTimeModeTitle) {
                customizedColumn.title = `${startOrEndDate === 'start' ? 'Start' : 'End'} (${
                    TimeModeEnum[schedule.viewSettings.timeMode]
                })`
            }

            if (hasColumnMenuWithFilter) {
                customizedColumn.columnMenu = ColumnMenuWithFilter
            }

            if (hasDropdownFilterCell) {
                customizedColumn.filterCell = (props: GridFilterCellProps) => {
                    return (
                        <DropdownFilterCell
                            {...props}
                            data={[CrewingFilterTrue, CrewingFilterFalse]}
                            defaultItem="Any"
                        />
                    )
                }
            }

            return customizedColumn
        }) || []

    return columns
}

export { getColumns }
