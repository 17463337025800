import { useState } from 'react'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import OperationModes from 'types/OperationModes'
import { FormControlChangeEvent, updateObjectWithFormEvent } from 'views/Common/Form/FormControls/FormControlBase'
import { DrillDownFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import ProfileCommonFields from '../ProfileCommonFields'
import AutoMarkerRulesDialog from './AutoMarkerRulesDialog'

interface AutoMarkersParametersProps {
    // validatedForm: boolean
    operationMode: OperationModes
    enableCommonFields: boolean
}

const ProfileAutoMarkersDetailsPanel = ({ operationMode, enableCommonFields }: AutoMarkersParametersProps) => {
    const [visibleDialog, setVisibleDialog] = useState<boolean>(false)

    const profile = useAppSelector((state) => state.profile.profileAutoMarkers)
    const dispatch = useAppDispatch()
    if (!profile) return null
    const { id, name, description, autoMarkerRules } = profile

    const onChange = (e: FormControlChangeEvent) => {
        const updated = updateObjectWithFormEvent(profile, e)
        dispatch(profileActions.setProfileByType({ profile: updated, type: 'Markers', isModified: true }))
    }

    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog)
    }

    return (
        <>
            {enableCommonFields && (
                <FormSection title="Details">
                    <ProfileCommonFields nameField={name} descriptionField={description} onChange={onChange} />
                </FormSection>
            )}

            <FormSection title={enableCommonFields ? 'Parameters' : null}>
                <DrillDownFormRow
                    labelText="Auto-Marker Rules"
                    subText="Rules to automatically create Marker events"
                    data={autoMarkerRules}
                    operationMode={operationMode}
                    onClick={toggleDialog}
                    value={`${autoMarkerRules.length} Rule${autoMarkerRules.length === 1 ? '' : 's'}`}
                />
            </FormSection>

            {visibleDialog && <AutoMarkerRulesDialog profileId={id} onClose={toggleDialog} />}
        </>
    )
}

export default ProfileAutoMarkersDetailsPanel
