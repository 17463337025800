import { DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { FormEvent, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import globals from 'services/global/globals'
import { showResponseMessage } from 'services/utilities/toastrUtils'
import { globalActions } from 'store/globalStore'
import { TimeModeEnum } from 'types/interfaces'
import ScheduleDetail from 'types/Schedule'
import { UpdateDutyTimeRequest } from 'types/UpdateDutyTimeRequest'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import DateTimePickerCustom from 'views/Common/Kendo/DateTimePickerCustom'

export interface EditDateTimeDialogProps {
    timeMode: TimeModeEnum
    timePosition: 'start' | 'end'
    dutyUuid: string
    scheduleId: number
    scheduleModified: Date
    startTime: Date
    startTimeLocalized: Date
    endTime: Date
    endTimeLocalized: Date
    closeCallback: (status: DialogResultEnum, updatedSchedule?: ScheduleDetail) => void
}

const EditDateTimeDialogContent = (props: EditDateTimeDialogProps) => {
    const [editedTime, setEditedTime] = useState<{ time: Date; timeLocalized: Date }>(() => {
        if (props.timePosition === 'start') {
            return {
                time: props.startTime,
                timeLocalized: props.startTimeLocalized,
            }
        }
        return {
            time: props.endTime,
            timeLocalized: props.endTimeLocalized,
        }
    })
    const api = globals.getApi().getScheduleApi()
    const dispatch = useDispatch()

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const start = props.timePosition === 'start' ? editedTime.time : props.startTime
        const requestData: UpdateDutyTimeRequest = {
            start,
            scheduleId: props.scheduleId,
            dutyUUID: props.dutyUuid,
            scheduleModified: props.scheduleModified,
        }
        if (props.timePosition === 'end') {
            requestData.end = editedTime.time
        }

        try {
            dispatch(globalActions.showLoadingModal())

            const [updatedSchedule, responseMessage] = await api.setDutyTime(requestData)
            showResponseMessage(responseMessage)
            props.closeCallback(DialogResultEnum.Completed, updatedSchedule)
        } catch (err: any) {
            // setErrorMessage(err.message)
            // todo: toastr
        } finally {
            dispatch(globalActions.hideLoadingModal())
        }
    }

    return (
        <>
            <Form id="editDateTimeDialog" noValidate onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>{`Date/Time Selection (${TimeModeEnum[props.timeMode]} Time)`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DateTimePickerCustom
                        value={editedTime.timeLocalized}
                        format={dateTimeFormatting.getKendoDateTimeFormat()}
                        onChange={(e: DateTimePickerChangeEvent) => {
                            let newDate = e.value
                            if (newDate === null) {
                                // user removed the date, just put it back
                                newDate = editedTime.timeLocalized
                            }
                            setEditedTime((previous) => {
                                const timeDiff = newDate!.getTime() - previous.timeLocalized.getTime()
                                const newTime = new Date(editedTime.time.getTime() + timeDiff)
                                return {
                                    timeLocalized: newDate!,
                                    time: newTime,
                                }
                            })
                        }}
                        width="250px"
                    />
                </Modal.Body>

                <Modal.Footer>
                    <ButtonCustom isLarge variant="primary" type="submit">
                        OK
                    </ButtonCustom>
                    <ButtonCustom
                        isLarge
                        variant="secondary"
                        onClick={() => props.closeCallback(DialogResultEnum.Cancelled)}
                    >
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </>
    )
}

const EditDateTimeDialog = (props: EditDateTimeDialogProps) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <EditDateTimeDialogContent {...props} />
        </ModalWrapper>
    )
}

export default EditDateTimeDialog
