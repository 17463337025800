import { AddHazardClassFromLibraryRequest, AddReportFromLibraryRequest } from 'types/AddFromLibraryRequest'
import { HazardClass, HazardClassDialogConfig, parseHazardClass } from 'types/HazardClass'
import { InsightsDashboardConfig } from 'types/InsightsDashboardConfig'
import InsightsMetadata, { parseInsightsConfigMetadata } from 'types/InsightsMetadata'
import { ReportDrilldownRequest } from 'types/ReportDrilldownRequest'
import ReportingMetadata, { parseReportConfigMetadata } from 'types/ReportingMetadata'
import { parseReport, Report, ReportDialogConfig } from 'types/Reports'
import { ScenarioSelection } from 'types/ScenarioSelection'
import Axios from '../axios/axios-sfc'
import SFApiBase from './sfApiBase'

class SFReportingApi extends SFApiBase {
    constructor(private axios: Axios) {
        super()
    }

    private sortItems = (items: { dashboardOrder: number }[]) => {
        items.sort((item1, item2) => {
            if (item1.dashboardOrder < item2.dashboardOrder) {
                return -1
            }
            if (item1.dashboardOrder > item2.dashboardOrder) {
                return 1
            }

            return 0
        })
    }

    saveReport = async (report: ReportDialogConfig): Promise<Report> => {
        const reportId = report.id > 0 ? report.id.toString() : ''
        const updatedReport = await (
            await this.axios.put(this.baseUrl + `/Api/Reports/${reportId}`, report, this.getConfig())
        ).data
        return parseReport(updatedReport)
    }

    deleteReport = async (reportId: number): Promise<void> => {
        await this.axios.delete(this.baseUrl + `/Api/Reports/${reportId}`, {}, this.getConfig())
    }

    /**
     * Get the reports dashboard configuration
     * @returns
     */
    getReportsDashboard = async (): Promise<ScenarioSelection> => {
        return (await this.axios.get(this.baseUrl + '/Api/Reports/DashboardConfig', this.getConfig()))
            .data as ScenarioSelection
    }

    /**
     * Get the insights dashboard configuration
     * @returns
     */
    getInsightsDashboard = async (): Promise<InsightsDashboardConfig> => {
        return (await this.axios.get(this.baseUrl + '/Api/Insights/DashboardConfig', this.getConfig()))
            .data as InsightsDashboardConfig
    }

    /**
     * Update reports dashboard config (selected scenarios)
     * @param dashboard
     */
    saveInsightsDashboard = async (dashboard: InsightsDashboardConfig): Promise<void> => {
        await this.axios.put(this.baseUrl + '/Api/Insights/DashboardConfig', dashboard, this.getConfig())
    }

    /**
     * Update reports dashboard config (selected scenarios)
     * @param dashboard
     */
    saveReportsDashboard = async (dashboard: ScenarioSelection): Promise<void> => {
        await this.axios.put(this.baseUrl + '/Api/Reports/DashboardConfig', dashboard, this.getConfig())
    }

    /**
     * Update the dashboard order to match the given report ids order.
     */
    saveReportsDashboardThumbnailOrder = async (reportIdsOrdered: number[]): Promise<void> => {
        await this.axios.put(
            this.baseUrl + '/Api/Reports/UpdateDashboardOrder',
            { idsOrdered: reportIdsOrdered },
            this.getConfig(),
        )
    }

    /**
     * Reset the reports dashboard to library defaults.
     */
    resetReportsDashboard = async (): Promise<Report[]> => {
        const reports = (await this.axios.get(this.baseUrl + '/Api/Reports/ResetDashboard', this.getConfig())).data
        return reports.map(parseReport)
    }

    /**
     * Reset the insights dashboard to defaults.
     */
    resetInsightsDashboard = async (): Promise<HazardClass[]> => {
        const hazardClasses = (await this.axios.get(this.baseUrl + '/Api/Insights/ResetDashboard', this.getConfig()))
            .data
        return hazardClasses.map(parseHazardClass)
    }

    /**
     * Get reports for the dashboard
     * @returns
     */
    getReports = async (): Promise<Report[]> => {
        const data = (await this.axios.get(this.baseUrl + '/Api/Reports', this.getConfig())).data as Array<any>
        const reports = data.map<Report>(parseReport)
        this.sortItems(reports)
        return reports
    }

    /**
     * Get a single report.
     */
    getReport = async (reportId: number): Promise<Report> => {
        const data = (await this.axios.get(this.baseUrl + `/Api/Reports/${reportId}`, this.getConfig())).data
        return parseReport(data)
    }

    /**
     * Get drilldown data for this report
     */
    getReportDrilldown = async (request: ReportDrilldownRequest): Promise<any> => {
        const xAxisFilterQueryParam = request.xAxisFilterValues
            .map((x) => `xAxisFilterValues=${encodeURIComponent(x)}`)
            .join('&')

        // todo: remove xAxisStart and end Indexes once we switch over to new v7 reporting
        const endpoint = `/Api/Reports/Drilldown?reportId=${request.reportId}&${xAxisFilterQueryParam}&visibleSeriesIds=${request.visibleSeriesIds}&xAxisStartIndex=${request.xAxisStartIndex}&xAxisEndIndex=${request.xAxisEndIndex}`

        return (await this.axios.get(this.baseUrl + endpoint, this.getConfig())).data
    }

    /**
     * Get drilldown data for this report
     */
    getReportDrilldownOld = async (request: ReportDrilldownRequest): Promise<any> => {
        return (
            await this.axios.get(
                this.baseUrl +
                    `/Api/Reports/DrilldownOld?reportId=${request.reportId}&xAxisStartIndex=${request.xAxisStartIndex}&xAxisEndIndex=${request.xAxisEndIndex}&visibleSeriesIds=${request.visibleSeriesIds}`,
                this.getConfig(),
            )
        ).data
    }

    /**
     * Get the reports library.
     */
    getReportsLibrary = async (): Promise<ReportDialogConfig[]> => {
        const data = (await this.axios.get(this.baseUrl + '/Api/Reports/Library', this.getConfig())).data as Array<any>
        return data.map<Report>(parseReport)
    }

    /**
     * Get the reports library.
     */
    getReportsLibraryDefaults = async (): Promise<ReportDialogConfig[]> => {
        const data = (await this.axios.get(this.baseUrl + '/Api/Reports/LibraryDefaultReports', this.getConfig()))
            .data as Array<any>
        return data.map<Report>(parseReport)
    }

    /**
     * Update the library with the given library reports configuration.
     */
    saveReportsLibrary = async (libraryReports: ReportDialogConfig[]): Promise<void> => {
        await this.axios.post(this.baseUrl + '/Api/Reports/Library', libraryReports, this.getConfig())
    }

    /**
     * Add library reports to the user's dashboard, start analysis or analyze synchronously
     */
    addLibraryReportsToDashboard = async (reports: AddReportFromLibraryRequest): Promise<Report[]> => {
        const rawReports = (
            await this.axios.post(this.baseUrl + '/Api/Reports/AddLibraryItemsToDashboard', reports, this.getConfig())
        ).data as any[]
        return rawReports.map(parseReport)
    }

    /**
     * Add library reports to the user's dashboard, start analysis or analyze synchronously
     */
    addLibraryHazardClassesToDashboard = async (reports: AddHazardClassFromLibraryRequest): Promise<HazardClass[]> => {
        const rawHazardClasses = (
            await this.axios.post(this.baseUrl + '/Api/Insights/AddLibraryItemsToDashboard', reports, this.getConfig())
        ).data as any[]
        return rawHazardClasses.map(parseHazardClass)
    }

    /**
     * Get metadata required for reports
     * @returns
     */
    getReportsConfigMetadata = async (usedColorsString?: string): Promise<ReportingMetadata> => {
        const usedColors = encodeURIComponent(usedColorsString || '')
        const data = (
            await this.axios.get(
                this.baseUrl + `/Api/Reports/ReportsConfigMetadata?usedColors=${usedColors}&numberToGet=100`,
                this.getConfig(),
            )
        ).data
        return parseReportConfigMetadata(data)
    }

    /**
     * Get metadata required for insights
     * @returns
     */
    getInsightsConfigMetadata = async (): Promise<InsightsMetadata> => {
        const data = (await this.axios.get(this.baseUrl + '/Api/Insights/InsightsConfigMetadata', this.getConfig()))
            .data
        return parseInsightsConfigMetadata(data)
    }

    /**
     * Create or update hazard class.
     * @param hazardClass
     * @returns
     */
    saveHazardClass = async (
        hazardClass: HazardClassDialogConfig,
        requiresRunInsights: boolean,
        requiresReorderPatterns: boolean,
    ): Promise<HazardClass> => {
        const updatedHazardClass = await (
            await this.axios.put(
                this.baseUrl + `/Api/Insights/${hazardClass.id}`,
                {
                    requiresRunInsights,
                    requiresReorderPatterns,
                    hazardClass,
                },
                this.getConfig(),
            )
        ).data
        return parseHazardClass(updatedHazardClass)
    }

    /**
     * Delete the hazard class with the given id.
     */
    deleteHazardClass = async (hazardClassId: number): Promise<void> => {
        await await this.axios.delete(this.baseUrl + `/Api/Insights/${hazardClassId}`, this.getConfig())
    }

    /**
     * Get all the hazard classes for Insights Dashbaord
     * @returns
     */
    getHazardClasses = async (): Promise<HazardClass[]> => {
        const data = (await this.axios.get(this.baseUrl + '/Api/Insights', this.getConfig())).data as Array<any>
        const hazardClasses = data.map<HazardClass>(parseHazardClass)
        this.sortItems(hazardClasses)
        return hazardClasses
    }

    /**
     * Get all the hazard classes for Insights Dashbaord
     * @returns
     */
    getHazardClass = async (hazardClassId: number): Promise<HazardClass> => {
        const data = (await this.axios.get(this.baseUrl + `/Api/Insights/${hazardClassId}`, this.getConfig())).data
        const hazardClass = parseHazardClass(data)
        return hazardClass
    }

    /**
     * Update the dashboard order to match the given hazard class ids order.
     */
    saveInsightsDashboardThumbnailOrder = async (hazardClassIdsOrdereed: number[]): Promise<void> => {
        await this.axios.put(
            this.baseUrl + '/Api/Insights/UpdateDashboardOrder',
            {
                idsOrdered: hazardClassIdsOrdereed,
            },
            this.getConfig(),
        )
    }

    updatePatternProperties = async (hazardClassId: number, patternId: number, name: string, hidden: boolean) => {
        await this.axios.put(
            this.baseUrl + '/Api/Insights/UpdatePatternProperties',
            {
                hazardClassId,
                patternId,
                name,
                hidden,
            },
            this.getConfig(),
        )
    }
}

export default SFReportingApi
