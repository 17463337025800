import { useSystemSettings } from 'hooks/useSettingValue'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { SettingValue, SystemSetting } from 'types/SystemSetting'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'
import FormSection from 'views/Common/Form/FormSection'
import ConfirmationDialog from 'views/Common/GenericDialogs/ConfirmationDialog'
import GeneralSettingList from './GeneralSettingConfig'
import Settings from './Settings'

type DialogMode = 'None' | 'SubmitConfirmation' | 'ResetConfirmation'

const GeneralSetting = () => {
    const [isChanged, setIsChanged] = useState(false)
    const [validatedForm, setValidatedForm] = useState(false)
    const [settings, setSettings, resetSettings] = useSystemSettings('General')
    const [dialogMode, setDialogMode] = useState<DialogMode>('None')

    const api = globals.getApi()

    useEffect(() => {
        document.title = 'SAFTE-FAST - General Settings'
    }, [])

    const handleOnChange = (e: FormControlChangeEvent) => {
        const updatedSettings = [...settings]
        const index = updatedSettings.findIndex((s) => s.name === e.target.name)
        if (index >= 0) {
            updatedSettings[index].value = e.target.value
            setSettings(updatedSettings)
            setIsChanged(true)
        }
    }

    const submitHandler = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        let invalid = false

        if (form.checkValidity() === false) {
            setValidatedForm(true)
            invalid = true
        }

        if (invalid) {
            return
        }

        setDialogMode('SubmitConfirmation')
    }, [])

    const performSubmit = async () => {
        const settingValues: SettingValue[] = settings.map((item) => ({
            name: item.name,
            value: item.value,
        }))

        await api.getSystemSettingApi().updateSystemSettings(settingValues)

        setIsChanged(false)
        toast.success('General Settings have been Updated.')
        setDialogMode('None')
    }

    const handleReset = useCallback(() => {
        resetSettings()
        setIsChanged(false)
        setDialogMode('None')
    }, [resetSettings])

    const displaySettings = GeneralSettingList.map((item) => {
        const list: SystemSetting[] = []
        item.items.forEach((element) => {
            const setting = settings.find((s) => s.name === element)
            if (setting) list.push(setting)
        })
        return (
            <FormSection title={item.section} key={item.section}>
                <Settings items={list} onChange={handleOnChange} />
            </FormSection>
        )
    })

    const title = 'General'
    const footer = (
        <FormFooter
            customCancelButtonText="Reset"
            disabledSave={!isChanged}
            disabledCancel={!isChanged}
            onCancel={() => setDialogMode('ResetConfirmation')}
        />
    )

    return (
        <FormPage headingContent={title} footerContent={footer} validatedForm={validatedForm} onSubmit={submitHandler}>
            {displaySettings}

            {dialogMode === 'SubmitConfirmation' && (
                <ConfirmationDialog
                    headerText="Save General Settings"
                    closeCallback={() => setDialogMode('None')}
                    confirmedCallback={() => performSubmit()}
                >
                    <p>Are you sure you want to save General Settings?</p>
                </ConfirmationDialog>
            )}

            {dialogMode === 'ResetConfirmation' && (
                <ConfirmationDialog
                    headerText="Reset General Settings"
                    secondaryButtonTextOverride="Cancel"
                    closeCallback={() => setDialogMode('None')}
                    confirmedCallback={handleReset}
                >
                    <p>Are you sure you want to reset your changes to General Settings?</p>
                </ConfirmationDialog>
            )}
        </FormPage>
    )
}

export default GeneralSetting
