import { AnyAction, Dispatch, ThunkAction } from '@reduxjs/toolkit'
import { AxiosProgressEvent } from 'axios'
import globals from 'services/global/globals'
import * as ProfileThunk from 'store/actions/profileActions'
import { profileActions } from 'store/profileStore'
import { RootState } from 'store/store'
import { ProfileModuleTypes } from 'types/EnumTypes'
import OperationModes from 'types/OperationModes'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { Scenario } from 'types/Scenario'
import { Schedule } from 'types/Schedule'
import TemplateDetail from 'types/TemplateDetail'

const setTemplateProfiles = (template: TemplateDetail): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch<any>) => {
        await dispatch(ProfileThunk.fetchAndSetProfileByType({ id: template.profileWorkId, type: 'Work' }))
        await dispatch(ProfileThunk.fetchAndSetProfileByType({ id: template.profileAutoSleepId, type: 'Sleep' }))
        await dispatch(
            ProfileThunk.fetchAndSetProfileByType({
                id: template.profileMetricsConfigurationId,
                type: 'Metrics',
            }),
        )
        await dispatch(
            ProfileThunk.fetchAndSetProfileByType({
                id: template.profileWorkloadId,
                type: 'Workload',
            }),
        )
        await dispatch(
            ProfileThunk.fetchAndSetProfileByType({
                id: template.profileAutoMarkerId,
                type: 'Markers',
            }),
        )

        if (template.profileDataSourceIds) {
            template.profileDataSourceIds.forEach(async (id) => {
                await dispatch(ProfileThunk.fetchAndSetProfileByType({ id, type: 'DataSource' }))
            })
        }
    }
}

const updateTemplateProfileId = (
    id: string,
    oldId: string,
    type: ProfileModuleTypes,
): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const stateTemplate = getState().profile.template
        if (!stateTemplate) {
            throw new Error('Template not found')
        }
        const updatedTemplate = { ...stateTemplate }
        if (type === 'Work') {
            updatedTemplate.profileWorkId = id
        } else if (type === 'Sleep') {
            updatedTemplate.profileAutoSleepId = id
        } else if (type === 'Metrics') {
            updatedTemplate.profileMetricsConfigurationId = id
        } else if (type === 'Workload') {
            updatedTemplate.profileWorkloadId = id
        } else if (type === 'Markers') {
            updatedTemplate.profileAutoMarkerId = id
        } else if (type === 'DataSource') {
            // datasource is an array, so deal with that.
            if (!updatedTemplate.profileDataSourceIds) {
                updatedTemplate.profileDataSourceIds = [id]
            } else {
                const index = updatedTemplate.profileDataSourceIds.indexOf(oldId)
                const updatedDataSourceIds = [...updatedTemplate.profileDataSourceIds]
                updatedDataSourceIds[index] = id
                updatedTemplate.profileDataSourceIds = updatedDataSourceIds
            }
        }

        // update state with the new profile
        dispatch(ProfileThunk.fetchAndSetProfileByType({ id, type, forceReset: true }))

        // update state with the template
        dispatch(profileActions.setTemplate({ template: updatedTemplate, modified: true }))
    }
}

const fetchAndSetTemplateFromId = (id: string): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        let template = getState().profile.template
        if (template && template.id === id && !getState().profile.templateModified) {
            // already in redux and not modified, so no need to fetch
            return
        }

        const isNew = id === 'new'
        if (isNew) {
            template = await globals.getApi().getTemplateApi().getDefaultTemplate()
            template.id = 'new'
        } else {
            // get from api
            template = await globals.getApi().getTemplateApi().getTemplate(id)
        }

        dispatch(profileActions.setTemplate({ template, modified: isNew }))
        dispatch(setTemplateProfiles(template))
    }
}

const setTemplateFromCopy = (id: string): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch<any>) => {
        const template = await globals.getApi().getTemplateApi().copyTemplate(id)
        await dispatch(profileActions.setTemplate({ template, modified: true }))
        await dispatch(setTemplateProfiles(template))
    }
}

const saveTemplateAndUpdatedProfiles = (scenario?: Scenario): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const state = getState()
        const profile = state.profile
        const template = state.profile.template
        if (!template) {
            return
        }

        const templateApi = globals.getApi().getTemplateApi()
        const profileApi = globals.getApi().getProfileApi()

        const updatedTemplate: TemplateDetail = { ...template } as TemplateDetail

        if (profile.profileWorkModified && profile.profileWork) {
            const updatedProfile = await profileApi.updateProfile(profile.profileWork, 'Work')
            updatedTemplate.profileWorkId = updatedProfile.id
        }
        if (profile.profileAutoSleepModified && profile.profileAutoSleep) {
            const updatedProfile = await profileApi.updateProfile(profile.profileAutoSleep, 'Sleep')
            updatedTemplate.profileAutoSleepId = updatedProfile.id
        }
        if (profile.profileMetricsConfigurationsModified && profile.profileMetricsConfigurations) {
            const updatedProfile = await profileApi.updateProfile(profile.profileMetricsConfigurations, 'Metrics')
            updatedTemplate.profileMetricsConfigurationId = updatedProfile.id
        }
        if (profile.profileWorkloadModified && profile.profileWorkload) {
            const updatedProfile = await profileApi.updateProfile(profile.profileWorkload, 'Workload')
            updatedTemplate.profileWorkloadId = updatedProfile.id
        }
        if (profile.profileAutoMarkersModified && profile.profileAutoMarkers) {
            const updatedProfile = await profileApi.updateProfile(profile.profileAutoMarkers, 'Markers')
            updatedTemplate.profileAutoMarkerId = updatedProfile.id
        }

        if (profile.profileDataSources) {
            const updatedIds = await Promise.all(
                profile.profileDataSources.map(async (ds) => {
                    if (ds.modified) {
                        const oldId = ds.profileDataSource.id
                        const updatedProfile = await profileApi.updateProfile(ds.profileDataSource, 'DataSource')
                        const newId = updatedProfile.id

                        // If the ID has changed, replace it with the updated ID
                        if (newId !== oldId) {
                            // if scenario provided, update any override records to reference updated profile id
                            const scenarioOverrideForUpdateProfile = scenario?.scenarioDataSourceOverrides.find(
                                (override) => override.profileDataSourceId === oldId,
                            )
                            if (scenarioOverrideForUpdateProfile) {
                                scenarioOverrideForUpdateProfile.profileDataSourceId = newId
                            }

                            // return new ID to update template DS ids list
                            return newId
                        }
                    }
                    return ds.profileDataSource.id // Return original ID if not modified or no profileDataSource
                }),
            )

            // Replace profileDataSourceIds with updated IDs
            updatedTemplate.profileDataSourceIds = updatedIds
        }

        const returnedTemplate: TemplateDetail = await templateApi.updateTemplate(updatedTemplate)
        updatedTemplate.id = returnedTemplate.id

        dispatch(profileActions.setTemplate({ template: updatedTemplate }))
    }
}

const addNewDataSourceToTemplate = (): ThunkAction<Promise<boolean>, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const state = getState()
        if (!state.profile.template) {
            throw new Error('Template is not set')
        }

        const api = globals.getApi().getProfileApi()
        const dataSourceProfile = await api.getDefaultProfile('DataSource')
        if (!dataSourceProfile) {
            return false
        }

        dispatch(profileActions.addNewDataSourceToTemplate(dataSourceProfile as ProfileDataSource))

        return true
    }
}
const saveScenarioAndUpdatedTemplate = (
    scenario: Scenario,
    mode: OperationModes,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const template = getState().profile.template
        const templateModified = getState().profile.templateModified
        if (!template) {
            return
        }

        // first save the template
        if (templateModified) {
            await dispatch(saveTemplateAndUpdatedProfiles(scenario))
        }

        const updatedTemplate = getState().profile.template // todo: why need to reference the state again here?
        scenario.templateId = updatedTemplate!.id

        // call the api
        const api = globals.getApi().getScenarioApi()

        if (mode === OperationModes.Edit) {
            const res = await api.updateScenario(scenario, onUploadProgress)
            scenario.id = res.id
        } else {
            const res = await api.createScenario(scenario, onUploadProgress)
            scenario.id = res.id
        }
    }
}

const saveScheduleAndUpdatedTemplate = (
    schedule: Schedule,
    mode: OperationModes,
): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        if (schedule.templateId) {
            const template = getState().profile.template
            const templateModified = getState().profile.templateModified
            if (!template) {
                return
            }

            // first save the template
            if (templateModified) {
                await dispatch(saveTemplateAndUpdatedProfiles())
            }

            const updatedTemplate = getState().profile.template // todo: why need to reference the state again here?
            schedule.templateId = updatedTemplate!.id
        }

        // call the api
        const api = globals.getApi().getScheduleApi()

        if (mode === OperationModes.Edit) {
            const res = await api.updateSchedule(schedule)
            schedule.id = res.id
        } else {
            const res = await api.createSchedule(schedule)
            schedule.id = res.id
        }
    }
}

export {
    updateTemplateProfileId,
    addNewDataSourceToTemplate,
    fetchAndSetTemplateFromId,
    setTemplateFromCopy,
    saveTemplateAndUpdatedProfiles,
    saveScenarioAndUpdatedTemplate,
    saveScheduleAndUpdatedTemplate,
}
