import { selectProfileByType } from 'store/actions/profileActions'
import { useAppSelector } from 'store/store'
import { InputTypes } from 'types/EnumTypes'
import { InputTypeOption } from 'types/interfaces'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { ScenarioDataSourceOverride } from 'types/Scenario'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import {
    InputDatePickerFormRow,
    InputFileFormRow,
    InputNumericFormRow,
    InputTextFormRow,
} from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'

interface ScenarioDataSourceOverridesPanelProps {
    profileId: string
    dataSourceOverride: ScenarioDataSourceOverride | undefined
    validated?: boolean
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>, profileDataSourceId: string, fileIndex: number) => void
    onOverrideChange: (event: FormControlChangeEvent, profileDataSourceId: string) => void
}

export const InputTypeOptions: Record<InputTypes, InputTypeOption> = {
    SAFTEFASTCSV: {
        name: 'SAFTE-FAST CSV',
        labelFileName1: 'SAFTE-FAST CSV File',
        labelDescription1: 'The selected schedules file (.csv, .sfcsv, .xls, .xlsx)  -  Select a new file to upload',
        showFilter: true,
    },
    SAFTEFASTXML: {
        name: 'SAFTE-FAST XML',
        labelFileName1: 'SAFTE-FAST XML File',
        labelDescription1: 'The selected schedules file (.xml, .sfxml)  -  Select a new file to upload',
        showFilter: true,
    },
    AltitudePairings: {
        name: 'Altitude Pairings',
        labelFileName1: 'Altitude Pairings File',
        labelDescription1: 'Local path to a pairings file (.txt)  -  Select a new file to upload',
    },
    CrewControlPairings: {
        name: 'Crew Control Pairings',
        labelFileName1: 'Crew Control Pairings File',
        labelDescription1: 'Local path to a pairings file (.csv, .xls)  -  Select a new file to upload',
    },
    CrewControlCSV: {
        name: 'Crew Control Rosters',
        labelFileName1: 'Crew Control Rosters File',
        labelDescription1: 'The selected rosters file (.csv, .xls)  -  Select a new file to upload',
    },
    CrewTracPRG: {
        name: 'Crew Trac PRG',
        labelFileName1: 'Crew Trac PRG File',
        labelDescription1: 'The selected pairings file (.prg, .txt)  -  Select a new file to upload',
    },
    CrewTracLIN: {
        name: 'Crew Trac LIN',
        labelFileName1: 'Crew Trac PRG File',
        labelDescription1: 'The selected pairings file (.prg, .txt)  -  Select a new file to upload',
        labelFileName2: 'Crew Trac LIN File',
        labelDescription2: 'The selected lines file (.lin, .txt)  -  Select a new file to upload',
    },
    FASPairings: {
        name: 'FAS Pairings',
        labelFileName1: 'FAS Pairings File',
        labelDescription1: 'The selected pairings file (.txt)  -  Select a new file to upload',
        showPeriod: true,
        showFilter: true,
    },
    FOSPairings: {
        name: 'FOS Pairings',
        labelFileName1: 'FOS Pairings File',
        labelDescription1: 'The selected pairings file (.csv, .xls, .xlsx)  -  Select a new file to upload',
        showFilter: true,
    },
    Fujitsu: {
        name: 'Fujitsu',
        labelFileName1: 'Fujitsu File',
        labelDescription1: 'The selected schedules file (.csv)  -  Select a new file to upload',
    },
    JeppesenCtf: {
        name: 'Jeppesen CTF',
        labelFileName1: 'Jeppesen CTF File',
        labelDescription1: 'The selected schedules file (.ctf)  -  Select a new file to upload',
        showFilter: true,
    },
    Jeppesen: {
        name: 'Jeppesen',
        labelFileName1: 'Jeppesen Pairings File',
        labelDescription1: 'The selected pairings file (.txt)  -  Select a new file to upload',
        labelFileName2: 'Jeppesen Roster File',
        labelDescription2: 'The selected rosters file (.zip)  -  Select a new file to upload',
        showYear: true,
        showFleet: true,
    },
    Kronos: {
        name: 'Kronos',
        labelFileName1: 'Kronos File',
        labelDescription1: 'The selected schedules file (.rpt, .asc)  -  Select a new file to upload',
    },
    S3RUS: {
        name: 'S3RUS',
        labelFileName1: 'S3RUS Pairings File',
        labelDescription1: 'The selected pairigngs file (.prg, .txt)  -  Select a new file to upload',
    },
    Zulu: {
        name: 'Zulu',
        labelFileName1: 'Zulu Sleep File',
        labelDescription1: 'The selected sleep file (.zip, .csv)  -  Select a new file to upload',
        showFilter: true,
    },
}

const ScenarioDataSourceOverridesPanel = ({
    profileId,
    dataSourceOverride,
    validated,
    onFileChange,
    onOverrideChange,
}: ScenarioDataSourceOverridesPanelProps) => {
    const profile = useAppSelector((state) => selectProfileByType(state, 'DataSource', profileId)) as ProfileDataSource

    if (!profile) return null

    const inputTypeOptions = InputTypeOptions[profile.dataSourceType]
    if (!inputTypeOptions) return null

    if (!dataSourceOverride) return null

    const requiresFile2 =
        (profile.dataSourceType === 'Jeppesen' && profile.scheduleType !== 'Pairings') ||
        profile.dataSourceType === 'CrewTracLIN'

    return (
        <FormSection>
            {inputTypeOptions.labelFileName1 && (
                <InputFileFormRow
                    labelText={inputTypeOptions.labelFileName1}
                    subText={inputTypeOptions.labelDescription1}
                    fieldId={`${profileId}:1`}
                    value={dataSourceOverride.fileName1}
                    onChange={(e) => onFileChange(e, profileId, 1)}
                    invalidText="Select a file"
                    validated={validated}
                    required
                />
            )}
            {inputTypeOptions.labelFileName2 && requiresFile2 && (
                <InputFileFormRow
                    labelText={inputTypeOptions.labelFileName2}
                    subText={inputTypeOptions.labelDescription2}
                    fieldId={`${profileId}:2`}
                    value={dataSourceOverride.fileName2}
                    onChange={(e) => onFileChange(e, profileId, 2)}
                    invalidText="Select a file"
                    validated={validated}
                    required
                />
            )}
            {inputTypeOptions.showPeriod && (
                <InputDatePickerFormRow
                    labelText="Period Start"
                    subText="Reference date for the first event start"
                    value={dataSourceOverride.periodDateStart}
                    onChange={(e) => onOverrideChange(e, profileId)}
                    fieldId="periodDateStart"
                    required
                />
            )}
            {inputTypeOptions.showYear && (
                <InputNumericFormRow
                    labelText="Schedule Year"
                    subText="Sets event dates to the specified year"
                    value={dataSourceOverride.year}
                    onChange={(e) => onOverrideChange(e, profileId)}
                    fieldId="year"
                    minValue={0}
                    invalidText="Enter a value of 0 or greater"
                    required
                />
            )}
            {inputTypeOptions.showFleet && requiresFile2 && (
                <InputTextFormRow
                    labelText="Fleet"
                    subText="Filters rosters to the specified fleet"
                    value={dataSourceOverride.fleet}
                    onChange={(e) => onOverrideChange(e, profileId)}
                    fieldId="fleet"
                    required
                />
            )}

            {inputTypeOptions.showFilter && profile.dateStartFilterEnable && (
                <InputDatePickerFormRow
                    labelText="Filter Date Start"
                    subText="Include events starting on or after"
                    value={dataSourceOverride.filterDateStart}
                    onChange={(e) => onOverrideChange(e, profileId)}
                    fieldId="filterDateStart"
                    required
                />
            )}
            {inputTypeOptions.showFilter && profile.dateEndFilterEnable && (
                <InputDatePickerFormRow
                    labelText="Filter Date End"
                    subText="Include events ending on or before"
                    value={dataSourceOverride.filterDateEnd}
                    onChange={(e) => onOverrideChange(e, profileId)}
                    fieldId="filterDateEnd"
                    required
                />
            )}
        </FormSection>
    )
}

export default ScenarioDataSourceOverridesPanel
