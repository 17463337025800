import useDetailPageParams from 'hooks/useDetailPageParams'
import { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { fetchAndSetTemplateFromId, saveScheduleAndUpdatedTemplate } from 'store/actions/templateActions'
import { useAppDispatch, useAppSelector } from 'store/store'
import { TagKeyValue } from 'types/ScheduleEvent'
import { FormControlChangeEvent, updateObjectWithFormEvent } from 'views/Common/Form/FormControls/FormControlBase'

import { profileActions } from 'store/profileStore'
import OperationModes from 'types/OperationModes'
import { Schedule } from 'types/Schedule'
import IconButtonAdd from 'views/Common/Buttons/IconButtonAdd'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'
import { InputTextAreaFormRow, InputTextFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import TagsTable from 'views/Common/Widget/TagsTable'
import ScenarioSaveConfirmationDialog from 'views/Scenarios/Page/ScenarioSaveConfirmationDialog'
import ProfilesSelectorPanel from 'views/Settings/Profiles/ProfilesSelectorPanel'
import TemplateExpansionPanel from 'views/Settings/Templates/TemplateExpansionPanel'
import { formatOperation, formatTitle } from 'views/viewUtils'

const ScheduleDetailPage = () => {
    const { id, operationMode } = useDetailPageParams()

    const tagsApi = globals.getApi().getTagsApi()
    const scheduleApi = globals.getApi().getScheduleApi()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [schedule, setSchedule] = useState<Schedule>()
    const [scheduleTagDefinitions, setScheduleTagDefinitions] = useState<string[]>([])
    const [scheduleModified, setScheduleModified] = useState(false)

    const templateChanged = useAppSelector((rs) => rs.profile.templateModified)
    const templateSelected = schedule?.templateId

    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [validatedForm, setValidatedForm] = useState(false)

    const areTagsInValid = () => {
        const scheduleTags = schedule?.tags || []
        const anyNoNameTags = scheduleTags.filter((x) => x.name === '').length > 0
        const hasDuplicates = scheduleTags.length !== new Set(scheduleTags.map((x) => x.name)).size
        return anyNoNameTags || hasDuplicates
    }

    // Set the scenario state based on the operation mode
    useEffect(() => {
        const init = async () => {
            dispatch(profileActions.setScenarioMode(true))
            try {
                setScheduleTagDefinitions(await tagsApi.getScheduleTagDefinitions())

                if (operationMode === OperationModes.Edit) {
                    const data = await scheduleApi.getScheduleDetails(Number(id))
                    setSchedule(data)
                    if (data.templateId) {
                        dispatch(fetchAndSetTemplateFromId(data.templateId))
                    }
                } else if (operationMode === OperationModes.Copy) {
                    const lastViewedScenarioId = Number(window.localStorage.getItem('lastViewedScenarioId')) // TODO how to get current scenario?
                    const data = await scheduleApi.copySchedule(Number(id), lastViewedScenarioId)
                    setSchedule(data)
                    if (data.templateId) {
                        dispatch(fetchAndSetTemplateFromId(data.templateId))
                    }
                    setScheduleModified(true) // copying a schedule results in it being 'modified'
                } else {
                    const lastViewedScenarioId = Number(window.localStorage.getItem('lastViewedScenarioId')) // TODO how to get current scenario?
                    const res = await scheduleApi.getDefaultSchedule(lastViewedScenarioId)
                    setSchedule(res as Schedule)
                    setScheduleModified(true) // adding a schedule with populated defaults should allow OK
                }
            } catch (error: any) {
                toast.error(error.message)
            }
        }
        init()
    }, [scheduleApi, tagsApi, dispatch, id, operationMode])

    const linkedMessage = () => {
        const msg = !templateSelected
            ? "This Schedule is linked to its Scenario's Template. Overriding the Template, Profiles, or Parameters here will unlink this Schedule from its Scenario."
            : "This Schedule is unlinked from its Scenario's Template. Use 'Link To Scenario' to restore this Schedule to its Scenario's Template."
        return <p style={{ textAlign: 'center', paddingTop: '20px' }}>{msg}</p>
    }

    const updateSchedule = (e: FormControlChangeEvent) => {
        setSchedule(updateObjectWithFormEvent(schedule, e))
        setScheduleModified(true)
    }

    const onAddTemplate = async () => {
        const updatedSchedule = { ...schedule } as Schedule

        // get scenario template id
        const scenarioApi = globals.getApi().getScenarioApi()
        const lastViewedScenarioId = window.localStorage.getItem('lastViewedScenarioId')
        const res = await scenarioApi.getScenario(Number(lastViewedScenarioId))

        updatedSchedule.templateId = res.templateId
        dispatch(fetchAndSetTemplateFromId(res.templateId))

        setSchedule(updatedSchedule)
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        if (!schedule) {
            throw Error('Schedule is not defined')
        }

        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        // valuidate
        setValidatedForm(true)

        const form = event.target as HTMLFormElement
        if (form.checkValidity() === false) {
            return
        }

        if (areTagsInValid()) {
            return
        }

        // create a new schedule, or open confirm dialog for edit
        if (operationMode !== OperationModes.Edit) {
            await performOperation(operationMode)
        } else {
            setShowConfirm(true)
        }
    }

    const handleModifyConfirmResult = async (dialogResult: DialogResultEnum) => {
        if (!schedule) {
            return
        }
        if (dialogResult === DialogResultEnum.Completed) {
            await performOperation(OperationModes.Edit)
        }

        setShowConfirm(false)
    }

    const performOperation = async (mode: OperationModes) => {
        if (!schedule) {
            return
        }

        try {
            await dispatch(saveScheduleAndUpdatedTemplate(schedule, mode))
            navigate(`/schedule/${schedule?.id}/view`, { replace: true })
        } catch (error: any) {
            toast.error(error.message)
            return
        }
        toast.success('Schedule has been ' + formatOperation(mode))
    }

    const title = formatTitle(operationMode, 'Schedule')
    const footer = (
        <FormFooter
            disabledSave={!scheduleModified && !templateChanged}
            onCancel={() => {
                if (operationMode === OperationModes.Edit) {
                    navigate(`/schedule/${id}/view`, { replace: true })
                    return
                }
                navigate('/schedules')
            }}
        />
    )

    const overrideTemplateButton = (
        <IconButtonAdd text="Override Template" style={{ display: 'block', marginTop: 10 }} onClick={onAddTemplate} />
    )

    if (!schedule) {
        return <></>
    }

    return (
        <FormPage headingContent={title} footerContent={footer} validatedForm={validatedForm} onSubmit={submitHandler}>
            <FormSection title="Details">
                <InputTextFormRow
                    labelText="Name"
                    fieldId="name"
                    subText="A unique name for this schedule"
                    value={schedule?.name}
                    onChange={updateSchedule}
                    required
                    invalidText="Enter a unique name"
                />
                <InputTextAreaFormRow
                    labelText="Description"
                    fieldId="description"
                    subText="A description for this schedule"
                    value={schedule?.description}
                    onChange={updateSchedule}
                />
                <InputTextFormRow
                    labelText="Base Station"
                    fieldId="baseLocation"
                    subText="The station used for initial location and pre-conditioning"
                    value={schedule?.baseLocation}
                    onChange={updateSchedule}
                    required
                    invalidText="Enter a valid base station"
                />
            </FormSection>
            <FormSection title="Tags">
                <TagsTable
                    tags={schedule.tags}
                    allTagNames={scheduleTagDefinitions}
                    showValidationErrors={validatedForm}
                    updateTags={(tags: TagKeyValue[]) => {
                        setScheduleModified(true)
                        setSchedule((previousSchedule: Schedule | undefined) => {
                            if (!previousSchedule) return previousSchedule
                            return {
                                ...previousSchedule,
                                tags,
                            }
                        })
                    }}
                />
            </FormSection>
            <FormSection title="Template">
                {linkedMessage()}
                {!templateSelected && overrideTemplateButton}
                {templateSelected && (
                    <TemplateExpansionPanel
                        onChange={updateSchedule}
                        onDelete={() => {
                            const updatedSchedule = { ...schedule }
                            updatedSchedule.templateId = ''
                            setSchedule(updatedSchedule)
                        }}
                    >
                        <ProfilesSelectorPanel />
                    </TemplateExpansionPanel>
                )}
            </FormSection>

            {showConfirm && (
                <ScenarioSaveConfirmationDialog title="Schedule" closeCallback={handleModifyConfirmResult} />
            )}
        </FormPage>
    )
}

export default ScheduleDetailPage
