import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfileModuleTypes } from 'types/EnumTypes'
import {
    Profile,
    ProfileAutoMarker,
    ProfileAutoSleep,
    ProfileDataSource,
    ProfileMetricsConfiguration,
    ProfileWork,
    ProfileWorkload,
} from 'types/ProfileInterfaces'
import TemplateDetail from 'types/TemplateDetail'

type ProfileDataSourceState = {
    profileDataSource: ProfileDataSource
    modified: boolean
}

export type ProfileStoreState = {
    profileWork: ProfileWork | null
    profileWorkModified: boolean
    profileAutoSleep: ProfileAutoSleep | null
    profileAutoSleepModified: boolean
    profileMetricsConfigurations: ProfileMetricsConfiguration | null
    profileMetricsConfigurationsModified: boolean
    profileWorkload: ProfileWorkload | null
    profileWorkloadModified: boolean
    profileAutoMarkers: ProfileAutoMarker | null
    profileAutoMarkersModified: boolean
    profileDataSources: ProfileDataSourceState[]
    template: TemplateDetail | null
    templateModified: boolean
    scenarioMode: boolean
}

const initialProfileState: ProfileStoreState = {
    profileWork: null,
    profileWorkModified: false,
    profileAutoSleep: null,
    profileAutoSleepModified: false,
    profileMetricsConfigurations: null,
    profileMetricsConfigurationsModified: false,
    profileWorkload: null,
    profileWorkloadModified: false,
    profileAutoMarkers: null,
    profileAutoMarkersModified: false,
    profileDataSources: [],
    template: null,
    templateModified: false,
    scenarioMode: false,
}

const profileReducer = createSlice({
    name: 'profile',
    initialState: initialProfileState,
    reducers: {
        clearProfilesAndTemplate(state) {
            Object.assign(state, initialProfileState)
        },

        setProfileByType(
            state,
            action: PayloadAction<{ type: ProfileModuleTypes; profile: Profile | null; isModified?: boolean }>,
        ) {
            const { type, profile, isModified = false } = action.payload
            if (!profile) {
                return
            }

            // If in template mode, and the profile does not already have a library id and is modified, set the profile's library id indicating this will be custom
            if (state.template && isModified) {
                profile.libraryId = profile.id
                assignTemplateLibraryId(state.scenarioMode, state.template, isModified)
                state.templateModified = true
            }

            switch (type) {
                case 'Work':
                    state.profileWork = profile as ProfileWork
                    state.profileWorkModified = isModified
                    break
                case 'Sleep':
                    state.profileAutoSleep = profile as ProfileAutoSleep
                    state.profileAutoSleepModified = isModified
                    break
                case 'Metrics':
                    state.profileMetricsConfigurations = profile as ProfileMetricsConfiguration
                    state.profileMetricsConfigurationsModified = isModified
                    break
                case 'Workload':
                    state.profileWorkload = profile as ProfileWorkload
                    state.profileWorkloadModified = isModified
                    break
                case 'Markers':
                    state.profileAutoMarkers = profile as ProfileAutoMarker
                    state.profileAutoMarkersModified = isModified
                    break
                case 'DataSource':
                    updateProfileDataSource(state, profile as ProfileDataSource, isModified)
                    break
                default:
                    break
            }
        },

        setTemplate(state, action: PayloadAction<{ template: TemplateDetail | null; modified?: boolean }>) {
            const { template, modified = false } = action.payload
            state.template = template
            if (template) {
                assignTemplateLibraryId(state.scenarioMode, template, modified)
                state.templateModified = modified
            } else {
                state.templateModified = false
            }
        },

        addNewDataSourceToTemplate(state, action: PayloadAction<ProfileDataSource>) {
            if (!state.template) throw new Error('Template is not set')

            assignTemplateLibraryId(state.scenarioMode, state.template, true)
            const newDataSource = action.payload
            state.profileDataSources.push({ profileDataSource: newDataSource, modified: false })
            state.template.profileDataSourceIds = [...(state.template.profileDataSourceIds || []), newDataSource.id]
            state.templateModified = true
        },

        removeDataSourceFromTemplate(state, action: PayloadAction<string>) {
            if (!state.template) throw new Error('Template is not set')

            assignTemplateLibraryId(state.scenarioMode, state.template, true)
            const id = action.payload
            state.profileDataSources = state.profileDataSources.filter((i) => i.profileDataSource.id !== id)
            state.template.profileDataSourceIds = state.template.profileDataSourceIds!.filter((i) => i !== id)
            state.templateModified = true
        },

        setScenarioMode(state, action: PayloadAction<boolean>) {
            state.scenarioMode = action.payload
        },
    },
})

function assignTemplateLibraryId(scenarioMode: boolean, template: TemplateDetail | null, modified: boolean) {
    if (scenarioMode && template && modified && !template.libraryId) {
        template.libraryId = template.id
    }
}

function updateProfileDataSource(state: ProfileStoreState, profileDatasource: ProfileDataSource, isModified: boolean) {
    // update profileDataSources details
    const index = state.profileDataSources.findIndex((i) => i.profileDataSource.id === profileDatasource.id)
    if (index >= 0) {
        state.profileDataSources[index] = { profileDataSource: profileDatasource, modified: isModified }
    } else {
        state.profileDataSources.push({ profileDataSource: profileDatasource, modified: isModified })
    }

    // if in template mode, update ds details list to match template ds ids list
    if (!state.template || !state.template.profileDataSourceIds) return

    state.profileDataSources = state.profileDataSources.filter((ds) =>
        state.template!.profileDataSourceIds!.includes(ds.profileDataSource.id),
    )
}

export const profileActions = profileReducer.actions
export default profileReducer.reducer
